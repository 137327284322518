
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormControl, 
  InputLabel
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { AppDispatch } from '../../store/store';
import { strings } from '../../language/strings';
import { loginAsync } from '../../redux/authSlice';
import { toastErrorSimple } from '../../utils/utils';

import './LoginWidget.css';

interface LoginWidgetProps { 
  setWidget:(widget : number)=>void;  
}

export const LoginWidget : React.FC<LoginWidgetProps> = props => {
  const { 
    setWidget,      
  } = props;

  const dispatch = useDispatch<AppDispatch>();
  
  /*const formEmpty = {
    email: 'eserra@zhintek.com',
    password: '123456',
  };*/

  const formEmpty = {
    email: '',
    password: '',
  };

  const [valoresFormulario, setValoresFormulario] = useState(formEmpty);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  function validateEmail(emailPrueba : string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(emailPrueba);
  }

  const handleLogin = (e : React.SyntheticEvent) => {
    e.preventDefault();
    if (valoresFormulario.email.length === 0 || valoresFormulario.password.length === 0 ) {
      toastErrorSimple(strings.camposObligatorios);
    }
    else if (!validateEmail(valoresFormulario.email)) {
      toastErrorSimple(strings.errorMail);
    } else if (valoresFormulario.password.length < 4) {
      toastErrorSimple(strings.errorPass);
    }
    else {
      dispatch(loginAsync(valoresFormulario.email, valoresFormulario.password));
    }
  };

  return ( 
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: 300 },
      }}
      noValidate
      autoComplete="off"
      style={{textAlign: 'center', margin: 'auto', padding: 32, paddingBottom: 32, backgroundColor: '#FFFFFF40', borderRadius: 16}}
    > 
      <img
        src={require('../../images/logo_bein.png')}
        style={{width: 270, height:90, marginBottom: 10}}
        alt='' 
      /> 
      <div>
        <TextField
          required
          id="email"
          label={strings.email}
          value={valoresFormulario.email}
          size='small'
          onChange={event=> setValoresFormulario({...valoresFormulario, email: event.target.value.trim()})}
        />
      </div>
      <div>
        <FormControl 
          sx={{ m: 1, width: 300 }}
          variant="outlined"
          required        
        >
          <InputLabel htmlFor="password" size='small'>Password</InputLabel>
          <OutlinedInput
            id="password"
            size='small'
            onChange={event=> setValoresFormulario({...valoresFormulario, password: event.target.value.trim()})}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            value={valoresFormulario.password}
          />
        </FormControl>                                 
      </div>
      <div>
        <Button
          variant="contained"
          color="secondary"
          size='small'
          className='btnLogin' 
          onClick={handleLogin} 
          style={{marginTop: 24, fontWeight: 'bold'}}      
        >
          {strings.sesion}
        </Button>
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          size='small'
          className='btnLogin' 
          onClick={()=> setWidget(1)} 
          style={{marginTop: 16, fontWeight: 'bold'}}      
        >
          {strings.newUser}
        </Button>
      </div>
      <div>
        <Button
          variant="contained"            
          size='small'
          className='btnLogin' 
          onClick={()=> setWidget(2)} 
          style={{backgroundColor: '#cc9900', marginTop: 16, fontWeight: 'bold'}}      
        >          
          {strings.recuperarPass}
        </Button>
      </div>
      <div style={{marginTop: 32}}>
        <text className='textAviso' onClick={()=> window.open(strings.condicionesURL)}>
            {strings.condiciones}
        </text>
        <br/>
        <text className='textAviso' onClick={()=> window.open(strings.avisoLegalURL)}>
            {strings.politica}
        </text>
      </div>
    </Box>
  );
}  
