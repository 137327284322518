
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper } from '@mui/material';

import { strings } from '../../language/strings';
import { MyInnercoinsSquare } from '../MyInnercoinsSquare/MyInnercoinsSquare';
import { DialogCompraPrivada } from './DialogCompraPrivada';
import { DialogReservar } from './DialogReservar';
import { DialogRecargar } from './DialogRecargar';
import { AppDispatch, RootState } from '../../store/store';
import { DialogHazteSocio } from './DialogHazteSocio';
import { setUser } from '../../redux/userSlice';
import { apiBeinclub } from '../../api/api';
import { CURRENT_USER, LAST_RECOVER_USER, URL_USER_ACCOUNT } from '../../utils/apiConstants';
import './MyWallet.css';
import { logoutAsync } from '../../redux/authSlice';

const RESERVA = 'RESERVA';
const COMPRA = 'COMPRA';
const RECARGA = 'RECARGA';

interface MyWalletProps {   
}

export const MyWallet : React.FC<MyWalletProps> = props => {
    /*const {       
    } = props;*/

    const dispatch = useDispatch<AppDispatch>();

    const user : User | null = useSelector((state : RootState) => state.user.currentUser);
    
    const [ dialogSocioVisible, setDialogSocioVisible ] = useState(false);
    const [ dialogReservarVisible, setDialogReservarVisible ] = useState(false);
    const [ dialogRecargarVisible, setDialogRecargarVisible ] = useState(false);
    const [ dialogCompraPrivadaVisible, setDialogCompraPrivadaVisible ] = useState(false);

    const checkIfSocio = async(tipoDeLlamada : string) =>{
      if(user!.customer === 0 || user!.customer === null) {  //SOCIO
        if(tipoDeLlamada === RESERVA){
          setDialogReservarVisible(true);
        }
        else if(tipoDeLlamada === RECARGA){
          setDialogRecargarVisible(true);
        }
        else if(tipoDeLlamada === COMPRA){
          setDialogCompraPrivadaVisible(true);
        }
      } else {  //PARTICIPANTE
        //Redux es Participante, chequear si ya cambio a SOCIO
        apiBeinclub.get(URL_USER_ACCOUNT)
        .then( res => {
          const userNow = res.data.data;
          localStorage.setItem(LAST_RECOVER_USER, new Date().getTime().toString());
          localStorage.setItem(CURRENT_USER, JSON.stringify(userNow));
          dispatch(setUser(userNow));

          //Para el cambio de PARTICIPANTE a SOCIO 
          if(user!.customer === 0) {  //SOCIO
            if(tipoDeLlamada === RESERVA){
              setDialogReservarVisible(true);
            }
            else if(tipoDeLlamada === RECARGA){
              setDialogRecargarVisible(true);
            }
            else if(tipoDeLlamada === COMPRA){
              setDialogCompraPrivadaVisible(true);
            }
          } else {
            setDialogSocioVisible(true);
          }
        }).catch((e) =>{
          console.log(e);
          if (e.response.status !== undefined && e.response.status === 401){
              //Usuario Caducado -> Logout
              dispatch(logoutAsync());
          }
        });
      }
    };

    return ( 
      <Paper elevation={2} className="colWallet" style={{ minWidth: 350, height: 550}}>
        <div className='containerWallet'>
          <MyInnercoinsSquare />
          <DialogReservar
            setDialogReservarVisible={setDialogReservarVisible}
            dialogReservarVisible={dialogReservarVisible}
          />
          <DialogRecargar
            setDialogRecargarVisible={setDialogRecargarVisible}
            dialogRecargarVisible={dialogRecargarVisible}
          />
          <DialogCompraPrivada
            setDialogCompraPrivadaVisible={setDialogCompraPrivadaVisible}
            dialogCompraPrivadaVisible={dialogCompraPrivadaVisible}
          />
          <DialogHazteSocio
            setDialogVisible={setDialogSocioVisible}
            dialogVisible={dialogSocioVisible}
          />
          <div onClick={() => checkIfSocio(RESERVA)}>
            <div className='viewBtn' style={{marginTop: 32}}>  
              <text className='btnText'>
                {strings.walletReservar}
              </text>                  
              <img
                src={require('../../images/reservar_icon.png')}
                className='iconBtn'
                alt='' 
              />           
            </div>
          </div>          
          <div onClick={() => checkIfSocio(RECARGA)}>
            <div className='viewBtn'>  
              <text className='btnText'>
                {strings.walletRecargar}
              </text>                  
              <img
                src={require('../../images/recargar_icon.png')}
                className='iconBtn'
                alt='' 
              />           
            </div>
          </div>
          <div onClick={() => checkIfSocio(COMPRA)}>
            <div className='viewBtn'>  
              <text className='btnText'>
                {strings.walletCompraPrivada}
              </text>                  
              <img
                src={require('../../images/compraprivada_icon.png')}
                className='iconBtn'
                alt='' 
              />           
            </div>
          </div>
        </div>
      </Paper>
    );
}  
