import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { apiBeinclub } from '../api/api';
import { AppDispatch } from '../store/store';
import { CURRENT_USER, LAST_RECOVER_USER, LAST_RENEW_TOKEN, URL_LOGIN, URL_SIGNUP, URL_USER_ACCOUNT } from '../utils/apiConstants';
import { setLoading } from './loadingSlice';
import { logoutAsync } from './authSlice';

export interface UserState {
  currentUser: User | null,
};

const initialState: UserState = {
  currentUser: localStorage.getItem(CURRENT_USER) ? JSON.parse(localStorage.getItem(CURRENT_USER)!) : null,  
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.currentUser = action.payload;
    },
    resetUser: (state) => {
      state.currentUser = null;
    },
  },
});

export const getUserRest = (withLoader : boolean = false) => async (dispatch : AppDispatch) => {
  withLoader && dispatch(setLoading(true));
    apiBeinclub.get(URL_USER_ACCOUNT)
      .then( res => {
        //console.log(res.data.data);
        localStorage.setItem(LAST_RECOVER_USER, new Date().getTime().toString());
        localStorage.setItem(CURRENT_USER, JSON.stringify(res.data.data));
        dispatch(setUser(res.data.data));
      }).catch((e) =>{
          console.log(e);
          if (e.response.status !== undefined && e.response.status === 401){
              //Usuario Caducado -> Logout
              //const user = JSON.parse(getLocalPreference(USER_DATA));
              //messaging().unsubscribeFromTopic(APPLICATION_ID + '-' + user.uuid).then(() => console.log('Unsubscribed to USER topic!'));
              dispatch(logoutAsync());
          }
      })
    .finally(()=> withLoader && dispatch(setLoading(false)));
};

export const resetCurrentUser = () => async (dispatch : AppDispatch) => { 
  //ELIMINAR USER
  dispatch(resetUser());  
};

export const { setUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
