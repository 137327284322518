
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  TextField,
  Button,
  Paper,
  Dialog,
  DialogContent,
} from '@mui/material';
import { ChevronLeft, CameraAltOutlined, Rotate90DegreesCwRounded } from '@mui/icons-material';

import AvatarEditor from 'react-avatar-editor';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { Formik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';

import { AppDispatch, RootState } from '../../store/store';
import { strings } from '../../language/strings';
import { getFormatCalendario, toastErrorSimple, toastSuccessSimple } from '../../utils/utils';
import { URL_UPDATE_USER, URL_UPLOAD_PHOTO } from '../../utils/apiConstants';
import { LoaderSpinnerOverlay } from '../Loader/LoaderSpinnerOverlay';
import { apiBeinclub } from '../../api/api';
import { getUserRest } from '../../redux/userSlice';
import './ProfileWidget.css';

interface ProfileWidgetProps {   
  //setWidget:(widget : number)=>void;  
}

export const ProfileWidget : React.FC<ProfileWidgetProps> = props => {
  /*const { 
  } = props;*/

  const formatoCalendario : string = getFormatCalendario();

  const formikRef = useRef<any>(null);
  const editor = useRef<any>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const user : User | null = useSelector((state : RootState) => state.user.currentUser);
  //console.log(user)

  const paises = useSelector((state : RootState) => state.resources.paises); 
  const idiomas = useSelector((state : RootState) => state.resources.idiomas); 
  const asesores = useSelector((state : RootState) => state.resources.asesores); 

  const miAsesor = asesores.find((obj : Advisor) => {
    if (user === undefined || user === null) {
      return undefined;
    }
    return obj.id === user!.agent_id;
  });

  const miPais = paises.find((obj : Country) => {
    if (user === undefined || user === null) {
      return undefined;
    }
    return obj.abbreviation === user!.country;
  });

  const miIdioma = idiomas.find((obj : Language) => {
    if (user === undefined  || user === null) {
      return undefined;
    }
    return obj.id === user!.language_id;
  });
  
  const [ loadingState, setLoadingState ] = useState(false);

  const [imageAvatar, setImageAvatar] = useState<any>(null);
  const [zoom, setZoom] = useState<number>(1);
  const [rotate, setRotate] = useState<number>(0);
  const [allowEnviar, setAllowEnviar] = useState(true);

  const [ countries, setCountries ] = useState<DropDown[]>([]);
  const [ paisSelected, setPaisSelected ] = useState(miPais && miPais !== null ? {id: miPais.abbreviation, label: miPais.name} : {id: '0', label: strings.registroPaisResidencia.replace('*', '')});

  const [ idiomasState, setIdiomasState ] = useState<DropDown[]>([]);
  const [ idiomaSelected, setIdiomaSelected ] = useState(miIdioma && miIdioma !== null ? {id: miIdioma.id.toString(), label: miIdioma.name} : {id: '0', label: strings.idioma});

  const [ asesoresState, setAsesoresState ] = useState<DropDown[]>([]);
  const [ asesorSelected, setAsesorSelected ] = useState(miAsesor && miAsesor !== null ? {id: miAsesor.id.toString(), label: miAsesor.username} : {id: '0', label: strings.asesor});
//const [ asesorError, setAsesorError ] = useState(false);

//const customers = [{id: '0', label: strings.datosSocio}, {id: '1', label: strings.datosInvitado}];
//const [ customer, setCustomer ] = useState({id: '1', label: strings.datosInvitado});

  const [ initialValuesFormik, setInitialValuesFormik ] = useState({
    firstName: user && user.first_name ? user.first_name.toString() : '',
    lastName: user && user.last_name ? user.last_name.toString() : '',
    phone: user && user.phone && user.phone.length > 1 ? user.phone.toString() : '',
    birthDate: user && (user.birthday ? user.birthday : ''),
    idioma: miIdioma ? miIdioma.name : '',
    asesor: miAsesor ? miAsesor.username : '',
    pais: miPais ? miPais.name : '',
  });

  const backNavigation = () => {
    formikRef.current.resetForm();
    navigate('/home');
  };

  useEffect(() => {
    if (paises !== null && paises !== undefined && paises.length > 0){
        let dataPaisesTransformada : DropDown[] = [];
        //let dataCodesPhoneTransformada : DropDown [] = [];
        paises.forEach((element : Country) => {
            dataPaisesTransformada.push(
                {
                    id: element.id.toString(),
                    label: element.name.toUpperCase(),
                });
                /*dataCodesPhoneTransformada.push(
                {
                    key: element.code.toString(),
                    label: '+' + element.code.toString(),
                });*/
        });
        setCountries(dataPaisesTransformada);
        //setCodesPhones(reorderArrayforKey(dataCodesPhoneTransformada));
    }

    if (idiomas !== null && idiomas !== undefined && idiomas.length > 0){
        let dataIdiomaTransformada : DropDown [] = [];
        idiomas.forEach((element : Language) => {
            dataIdiomaTransformada.push(
                {
                    id: element.id.toString(),
                    label: element.name.toUpperCase(),
                });
        });
        setIdiomasState(dataIdiomaTransformada);
    }

    if (asesores !== null && asesores !== undefined && asesores.length > 0){
        let dataAsesoresTransformada : DropDown[] = [];
        asesores.forEach((element : Advisor) => {
            dataAsesoresTransformada.push(
                {
                    id: element.id.toString(),
                    label: element.username,
                });
        });
        setAsesoresState(dataAsesoresTransformada);
    }
  }, [paises, idiomas, asesores]);

  const datosPersonalesValidationSchema = yup.object().shape({
    firstName: yup
        .string()
        .required(strings.obligatorio),
    lastName: yup
        .string()
        .required(strings.obligatorio),
    birthDate: yup
        .string()
        .required(strings.obligatorio),
    phone: yup
        .string()
        .required(strings.obligatorio),
    /*nif: yup
        .string()
        .required(strings.obligatorio),*/
  });

  const handleSubmitClick = (values : any) =>{ 
    //Esta Ok
    let userBuffer = {
        'email': user!.email,

        'first_name': values.firstName && values.firstName.trim().length > 0 && values.firstName.toString().trim(),
        'last_name': values.lastName && values.lastName.trim().length > 0 && values.lastName.toString().trim(),
        'phone': values.phone && values.phone.trim().length > 0 && values.phone.toString().trim(),
        'birthday': values.birthDate !== user?.birthday ? moment(new Date(values.birthDate._d)).format('YYYY-MM-DD') : user?.birthday,            
        'country': paisSelected.id !== '0' ? paisSelected.id : null,
        'language_id': idiomaSelected.id !== '0' ? idiomaSelected.id : null,
        'agent_id': asesorSelected.id !== '0' ? asesorSelected.id : null,
    }; 
    userUpdate(userBuffer);
  };

  const userUpdate = (userBuffer : any) => {
    setLoadingState(true);
    apiBeinclub.post(URL_UPDATE_USER, JSON.stringify(userBuffer))
        .then( (res : any) => {
            console.log(res);
            if (res.data.status) {
              //actualiza user redux
              dispatch(getUserRest());
              toastSuccessSimple(strings.toastProfile);
              //Envia a Home
              setTimeout(function(){
                backNavigation();
              }, 1500);
            } else if (res.data.status === 0) {
              toastErrorSimple(res.data.message.email[0]);
            }            
        }).catch((e : any) =>{
            console.log(e);
            toastErrorSimple(strings.mal);            
        })
        .finally(()=>setLoadingState(false));
  };

  /////////////// AVATAR PHOTO //////////////////////
  const onChangeAvatar = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    setImageAvatar(imageList[0]);
  };

  const closeAvatarDialog = ()=>{
    setImageAvatar(null);
    setZoom(1);
    setRotate(0);
  };

  const handleSubmitAvatar = async () =>{
    if (allowEnviar) {
        const imagen = editor.current.getImage().toDataURL();
        const imgToSend = imagen.substring(imagen.indexOf(',') + 1)

        const parameters = {'image': imgToSend};
        setAllowEnviar(false);
        apiBeinclub.post(URL_UPLOAD_PHOTO, JSON.stringify(parameters))
        .then( res => {
            console.log('Exito!!!!');
            dispatch(getUserRest());
            closeAvatarDialog();
            setAllowEnviar(true);
        }).catch((e) =>{
            console.log(e);
            toastErrorSimple(strings.mal);
            setAllowEnviar(true);
        });
    }
  };

  const modalEditAvatar = ()=>{
    return (
      <Dialog
        open={imageAvatar !== null}
        onClose={()=>closeAvatarDialog()}  
      >
        <DialogContent sx={{width: '360px'}}>
          <AvatarEditor
            ref={editor}
            image={imageAvatar !== null ? imageAvatar.dataURL : ''}
            width={300}
            height={300}
            border={30}
            color={[0, 0, 0, 0.7]} // RGBA
            scale={zoom}
            rotate={rotate}
          />
          <div style={{display: 'flex', flexDirection: 'row', marginTop: 0, marginLeft : -22, justifyContent: 'center', alignItems: 'center'}}>
            <Button
              size='small'
              color="primary"  
              onClick={()=> zoom > 1.5 && setZoom(zoom - 0.5)} 
              style={{ fontWeight: 'bold', padding: 0, fontSize: 20}}      
            >
              -
            </Button>
            <text onClick={()=> setZoom(1)} style={{fontWeight: 'bold', fontSize: 12, marginLeft: 16, marginRight: 16, cursor: 'pointer'}}>ZOOM</text>
            <Button
              size='small'
              color="primary"  
              onClick={()=> setZoom(zoom + 0.5)} 
              style={{fontWeight: 'bold', padding: 0, fontSize: 20}}      
            >
              +
            </Button>           
            <Rotate90DegreesCwRounded style={{fontSize: 20, color: '#cc9900', cursor: 'pointer', marginLeft: 32}} onClick={() => rotate === 270 ? setRotate(0) : setRotate(rotate + 90)} />            
          </div>
          <div style={{display: 'flex', flexDirection: 'row', marginTop: 16, justifyContent: 'center', alignItems: 'center'}}>
            <Button
              variant="contained"
              size='small'
              disabled={!allowEnviar}
              onClick={()=> handleSubmitAvatar()} 
              style={{fontWeight: 'bold', width: 100}}      
            >
              {strings.enviarBig}
            </Button>
            <Button
              variant="contained"            
              size='small'
              disabled={!allowEnviar}
              onClick={()=> closeAvatarDialog()} 
              style={{backgroundColor: 'gray', fontWeight: 'bold', width: 100, marginLeft: 8}}      
            >          
              {strings.cancelBig}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }; 
  /////////////////////////////////////////////////////// 

  return ( 
    <Paper
      elevation={2}
      style={{width: 700, textAlign: 'center', margin: 'auto', padding: 32, marginTop: 24}}
    > 
      <LoaderSpinnerOverlay visible={loadingState}/> 
      <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', position: 'relative', marginBottom: 8}}>               
        <ChevronLeft style={{fontSize: 50, position: 'absolute', left: -12, top: -6}} onClick={()=> backNavigation()}/>
        <ImageUploading        
          value={imageAvatar}
          onChange={onChangeAvatar}
        >
          {({ onImageUpload, }) => (
            <div
              style={{position: 'relative'}}
              onClick={onImageUpload}
            >
              <div style={{position: 'absolute', right: 5, bottom: 5, backgroundColor: 'white',width: 32, height: 32 , borderRadius: 100, alignItems: 'center', justifyContent: 'center', border: '1px solid black'}}>
                <CameraAltOutlined style={{fontSize: 25, paddingTop: 4}}/> 
              </div>
                     
              <img
                src={
                  user?.avatar_thumb_url ?
                    user?.avatar_thumb_url
                    : 
                    require('../../images/ic_profile.png')                 
                }
                className='avatarProfile'
                alt='' 
              />  
            </div> 
          )}
        </ImageUploading>                              
      </div> 
      <div>
      { modalEditAvatar() }
      </div>
      <Formik
        innerRef={formikRef}
        validationSchema={datosPersonalesValidationSchema}
        enableReinitialize={true}
        initialValues={initialValuesFormik}
        onSubmit={(values : any) =>{ handleSubmitClick(values);}}
      >
        {({ setFieldValue, handleChange, handleBlur, handleSubmit, values, errors, touched, isValid }) => ( 
          <div> 
            <div style={{textAlign: 'left'}}>
              <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                <div style={{width: '100%'}}>
                  <TextField
                    id='firstName'
                    onBlur={handleBlur('firstName')}
                    onChange={handleChange('firstName')}
                    value={values.firstName}
                    label={strings.nombre}
                    type='text'
                    size='small'
                    className='inputProfile'
                    style={{marginTop: 16}}
                  /> 
                  {(errors.firstName && touched.firstName) &&
                    <text className='errorTextProfile'>{errors.firstName}</text>
                  }
                </div>
                <div style={{width: '100%', marginLeft: 12}}>
                  <TextField
                    id='lastName'
                    onBlur={handleBlur('lastName')}
                    onChange={handleChange('lastName')}
                    value={values.lastName}
                    label={strings.apellidos}
                    type='text'
                    size='small'
                    className='inputProfile'
                    style={{marginTop: 16}}
                  /> 
                  {(errors.lastName && touched.lastName) &&
                    <text className='errorTextProfile'>{errors.lastName}</text>
                  }
                </div>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                <div style={{width: '100%'}}>
                  <TextField
                    disabled={true}
                    id='email'                    
                    value={user?.email}
                    label={strings.emailValido}
                    type='email'
                    size='small'
                    className='inputProfile'
                    style={{marginTop: 16}}
                  /> 
                </div>
                <div style={{width: '100%', marginLeft: 12}}>
                  <TextField
                    id='phone'
                    onBlur={handleBlur('phone')}
                    onChange={handleChange('phone')}
                    value={values.phone}
                    label={strings.pcPhone + strings.asterisco}
                    type='tel'
                    size='small'
                    className='inputProfile'
                    style={{marginTop: 16}}
                  />             
                  {(errors.phone && touched.phone) &&
                    <text className='errorTextProfile'>{errors.phone}</text>
                  }
                </div>  
              </div>  
              <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginTop: 16}}> 
                <div style={{width: '100%'}}> 
                { /* FECHA DE ALTA */ }
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      disabled={true}
                      label={strings.datosAltaSocio}                      
                      format={formatoCalendario}
                      slotProps={{
                        textField: {
                          size: 'small',
                          value: moment(user?.created_at),
                          disabled: true,
                          select: false,
                        },
                      }}
                      sx={{width: '100%'}}                  
                    />
                  </LocalizationProvider>   
                </div>
                <div style={{width: '100%', marginLeft: 12}}>      
                  { /* FECHA DE NACIMIENTO */ }
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label={strings.birthDate + strings.asterisco}
                      value={moment(values.birthDate)}
                      onChange={(newValue) => setFieldValue('birthDate', newValue)}
                      format={formatoCalendario}
                      //maxDate={new Date()}
                      slotProps={{
                        textField: {
                          size: 'small',
                          onBlur: handleBlur('birthDate'),
                        },
                      }}
                      sx={{width: '100%', marginBottom: (errors.birthDate && touched.birthDate) ? '2px' : '10px' }}                  
                    />
                  </LocalizationProvider>
                  {(errors.birthDate && touched.birthDate) &&
                    <text className='errorTextProfile' style={{marginTop: -5}}>{errors.birthDate}</text>
                  }             
                </div>
              </div>              
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginTop: 6}}>
              <div style={{width: '100%'}}>
                <TextField
                  disabled={true}
                  id='customer'                    
                  value={
                    user && user!.customer !== undefined &&
                      (
                        user.customer === 0 || user.customer === null ?
                        strings.datosSocio
                        :
                        strings.datosInvitado
                      )
                  }
                  label={strings.datosTipoUsuario}
                  size='small'
                  className='inputProfile'  
                  />                        
              </div>
              <div style={{width: '100%', marginLeft: 12}}>
              <Select
                  id='assessorId'
                  placeholder={strings.asesor}
                  noOptionsMessage={()=> '...'}                  
                  onChange={(value : any)=> setAsesorSelected(value)}
                  options={asesoresState}
                  defaultValue={asesorSelected}
                  className='inputRegister'
                  maxMenuHeight={200}
                  styles={{
                      control: ({ borderColor, boxShadow, ...provided }, { theme }) => ({
                          ...provided, 
                          backgroundColor: 'transparent',
                          borderColor: '#C4979B',
                          '&:hover': {
                              borderColor: '#ff0066',
                          }
                      }),
                      menu: (defaultStyles: any) => ({
                        ...defaultStyles,
                        backgroundColor: 'white',
                      })
                  }}
                  onBlur={handleBlur('assessorId')}
                /> 
              </div>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginTop: 16}}>
                <div style={{width: '100%'}}>
                  <Select
                    id='countryId'
                    placeholder={strings.registroPaisResidencia}
                    noOptionsMessage={()=> '...'}                  
                    onChange={(value : any)=> setPaisSelected(value)}
                    options={countries}
                    defaultValue={paisSelected}
                    className='inputRegister'
                    maxMenuHeight={200}
                    styles={{
                        control: ({ borderColor, boxShadow, ...provided }, { theme }) => ({
                            ...provided, 
                            backgroundColor: 'transparent',                  
                            borderColor: '#C4979B',
                            '&:hover': {
                                borderColor: '#ff0066',
                            }
                        }),
                        menu: (defaultStyles: any) => ({
                          ...defaultStyles,
                          backgroundColor: 'white',
                        })
                    }}
                    onBlur={handleBlur('countryId')}
                  />
                </div>
              <div style={{width: '100%', marginLeft: 12}}>
                <Select
                  id='langId'
                  placeholder={strings.idioma + strings.asterisco}
                  noOptionsMessage={()=> '...'}                  
                  onChange={(value : any)=> setIdiomaSelected(value)}
                  options={idiomasState ? idiomasState : []}
                  defaultValue={idiomaSelected}
                  className='inputRegister'
                  maxMenuHeight={200}
                  styles={{
                      control: ({ borderColor, boxShadow, ...provided }, { theme }) => ({
                          ...provided, 
                          backgroundColor: 'transparent',                  
                          borderColor: '#C4979B',
                          '&:hover': {
                              borderColor: '#ff0066',
                          }
                      }),
                      menu: (defaultStyles: any) => ({
                        ...defaultStyles,
                        backgroundColor: 'white',
                      })
                  }}
                  onBlur={handleBlur('langId')}
                />      
              </div>     
            </div>
            </div>      
            <div>
              <Button
                variant="contained"
                color="primary"
                size='small'
                className='btnProfile' 
                onClick={()=> handleSubmit()} 
                disabled={loadingState}
                style={{marginTop: 32, fontWeight: 'bold'}}      
              >
                {strings.enviarBig}
              </Button>
            </div>
          </div>   
        )}   
      </Formik>   
    </Paper>
  );
}  
