import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Refresh } from '@mui/icons-material';
import { CircularProgress, Paper } from '@mui/material';

import { AppDispatch, RootState } from '../../store/store';
import { getUserRest } from '../../redux/userSlice';
import './MyInnercoinsSquare.css';
import { getMisMovimientosRest } from '../../redux/misMovimientosSlice';

interface MyInnercoinsSquareProps {
}

export const MyInnercoinsSquare : React.FC<MyInnercoinsSquareProps> = props => {

    /*const {
    } = props;*/

    const dispatch = useDispatch<AppDispatch>();

    const user : User | null = useSelector((state : RootState) => state.user.currentUser);
    const loading = useSelector((state : RootState) => state.loading.loading);

    return (
        <Paper square elevation={16} className='viewSquare'>
            {
                !loading ?
                <div 
                    style={{cursor: 'pointer'}}
                    onClick={
                        () => {
                            dispatch(getUserRest(true));
                            user !== null && dispatch(getMisMovimientosRest(user));   //lanzamos recuperación de MisMovimientos también
                        }}
                    >
                    <div style={{textAlign: 'right' }}>
                        <Refresh style={{fontSize: 30, color:'#ff0066'}} />
                    </div>
                </div>
                :
                <div style={{textAlign: 'right' }}>
                    <CircularProgress style={{height: 25, width: 25, color:'#ff0066', marginTop: 5, marginRight: 4}} />
                </div>
            }
            <div style={{display: 'flex', flexDirection: 'column'}}>
            <text style={{fontSize: 65, fontWeight: 'bold', userSelect: 'none'}}>
                {user && user.balance}
            </text>
            <text style={{fontSize: 18, fontWeight: 'bold', color: '#7a7a7a', userSelect: 'none'}}>
                {user && user.name}
            </text>
            </div>
        </Paper>
    );
};
