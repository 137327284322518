
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Paper,
    Tabs,
    Tab,
    Box,
    List,
    ListItem,
} from '@mui/material';

import { strings } from '../../language/strings';
import './MisMovimientos.css';
import { RootState } from '../../store/store';

//////// TABS ///////////////////
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    tabNumber: number;
}
  
function TabPanel(props: TabPanelProps) {
    const { children, tabNumber, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={tabNumber !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {tabNumber === index && (
                <Box sx={{ p: 3 }}>
                    <text>{children}</text>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
///////////////////////////////////////
  

interface MisMovimientosProps {   
}

export const MisMovimientos : React.FC<MisMovimientosProps> = props => {
    /*const {       
    } = props;*/

    const todosMovimientos : any [] = useSelector((state : RootState) => state.movimientos.misMovimientos);   
    const ingresos : any [] = useSelector((state : RootState) => state.movimientos.ingresos);
    const gastos : any [] = useSelector((state : RootState) => state.movimientos.gastos);

    const [tabNumber, setTabNumber] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabNumber(newValue);
    };

    const renderTab = (transacciones : Transaction[], conSaldo : boolean = true) => (        
        transacciones.length > 0 ?
            <List sx={{ overflow: 'auto', maxHeight: 400 }}>
                {transacciones.map((transact : Transaction) => (
                    <ListItem                    
                        key={transact.id}
                        //disableGutters
                        style={{paddingLeft:4, paddingRight:4,  marginBottom: -10}}                  
                    >
                        <table width={'100%'}>
                            <tbody>
                                <tr>
                                    <td style={{width: '85%'}}>
                                        <text className='tituloMov'>                                         
                                            { transact.meta !== null ? JSON.parse(transact.meta).concept : '' }
                                        </text>
                                    </td>
                                    <td style={{width: '15%', textAlign: 'right'}}>
                                        <text className='tituloMov'>
                                            { transact.type === 'withdraw' ? transact.amount : '+' + transact.amount }
                                        </text>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width: '85%'}}>
                                        <text className='contenidoMov'>
                                            { new Date(transact.created_at).toLocaleDateString(strings.getInterfaceLanguage()) }
                                            { /*transact.created_at.toLocaleDateString('en-EN')*/ }                                        
                                        </text>
                                    </td>
                                    {
                                        conSaldo &&
                                        <td style={{width: '15%', textAlign: 'right'}}>
                                            <text className='contenidoMov'>
                                                { transact.saldo }
                                            </text>
                                        </td>
                                    }
                                    
                                </tr>
                            </tbody>
                        </table>                    
                    </ListItem>
                ))}
            </List>
            :
            null
    );
    
    return ( 
        <Paper elevation={2} className="colMovimientos" style={{ minHeight: 550}}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                <Tabs 
                    value={tabNumber} 
                    onChange={handleChange} 
                    variant="fullWidth"
                >
                    <Tab label={strings.movTodo} {...a11yProps(0)} />
                    <Tab label={strings.movIngresos} {...a11yProps(1)} />
                    <Tab label={strings.movGastos} {...a11yProps(2)} />
                </Tabs>
            </Box>      
            <TabPanel tabNumber={tabNumber} index={0}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: 1, textAlign: 'center'}}>
                    {strings.movEsteMes}
                </Box> 
                { renderTab(todosMovimientos) } 
            </TabPanel>
            <TabPanel tabNumber={tabNumber} index={1}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: 1, textAlign: 'center'}}>
                    {strings.movEsteMes}
                </Box> 
                { renderTab(ingresos, false) }   
            </TabPanel>
            <TabPanel tabNumber={tabNumber} index={2}>      
                <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: 1, textAlign: 'center'}}>
                    {strings.movEsteMes}
                </Box> 
                { renderTab(gastos, false) } 
            </TabPanel>
        </Paper>
    );
}  
