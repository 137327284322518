import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Button,
  TextField,
} from '@mui/material';

import { Formik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';

import { AppDispatch, RootState } from '../../store/store';
import { apiBeinclub } from '../../api/api';
import { toastErrorSimple, toastSuccessSimple } from '../../utils/utils';
import { strings } from '../../language/strings';
import { URL_RESERVA_O_COMPRA_PRIVADA, URL_VENDEDOR } from '../../utils/apiConstants';
import { getUserRest } from '../../redux/userSlice';

import './MyWallet.css'

interface DialogCompraPrivadaProps {
    dialogCompraPrivadaVisible: boolean;
    setDialogCompraPrivadaVisible: (arg0: boolean) => void;
}

export const DialogCompraPrivada : React.FC<DialogCompraPrivadaProps> = props => {
    const {
        dialogCompraPrivadaVisible,
        setDialogCompraPrivadaVisible,
    } = props;

    const dispatch = useDispatch<AppDispatch>();

    const user : User | null = useSelector((state : RootState) => state.user.currentUser);

    const [ btnAvailable, setBtnAvailable ] = useState(true);
    const [ vendedores, setVendedores ] = useState<DropDown[]>([]);
    const [ vendedorSelected, setVendedorSelected ] = useState<any | null>(null);

    const formikRefCompra = useRef<any | null>(null);

    const [ initialValuesFormik, setInitialValuesFormik ] = useState({
        importeTransferir: '',
        vendedor: null,
        concepto: '',
    });

    const dialogCompraValidationSchema = yup.object().shape({
        importeTransferir: yup
            .string()
            .required(strings.obligatorio)
            .test(
                '',
                strings.sinDecimales ,
                (value : string) => value !== undefined && !value!.includes('.') && !value!.includes(','),
              ),
        concepto: yup
            .string()
            .required(strings.obligatorio),
    });

    useEffect(() => {
        apiBeinclub.get(URL_VENDEDOR)
        .then( res => {
          //console.log(res);
          if (res.data.data[0] !== null){
              let dataVendedorTransformada : DropDown [] = [];
              res.data.data.forEach((element : Seller) => {
                dataVendedorTransformada.push(
                      {
                          id: element.id.toString(),
                          label: element.username,
                      });
              });
              setVendedores(dataVendedorTransformada);
          }
        }).catch((e) =>{
            console.log(e);
        });
    }, []);

    const resetBack = () => {
      setVendedorSelected(null);
      formikRefCompra.current!.resetForm();
      setDialogCompraPrivadaVisible(false);
  };

  const handleSubmitClick = (values : any) =>{
    if (vendedorSelected !== null) {
        if (user!.balance && (parseInt(user!.balance) < parseInt(values.importeTransferir))) {
          toastErrorSimple(strings.faltaInnercoins);
        } else {
          setBtnAvailable(false);
          const parametros = {
              'price': values.importeTransferir,
              'recipient_user': vendedorSelected.id,
              //'attribute_category': categoriaSelected.id,
              'payment_concept': values.concepto,
          };

          console.log(parametros)

          apiBeinclub.post(URL_RESERVA_O_COMPRA_PRIVADA, JSON.stringify(parametros))
          .then( res => {
              //console.log(res);
              dispatch(getUserRest());
              resetBack();
              toastSuccessSimple(strings.compraRealizada);
          }).catch((e) =>{
              toastErrorSimple(strings.mal);
              console.log(e);
          }).finally(()=> setBtnAvailable(true));
        }
    }
  };

  return (
    <>
      <Formik
          innerRef={formikRefCompra}
          validationSchema={dialogCompraValidationSchema}
          enableReinitialize={true}
          initialValues={initialValuesFormik}
          onSubmit={(values : any) =>{ handleSubmitClick(values); }}
      >
      {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
          <Dialog
            open={dialogCompraPrivadaVisible}
            onClose={resetBack}                      
          >
          <DialogContent sx={{width: '400px'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 4}}>
              <img
                src={require('../../images/dialog_compra_icon.png')}
                className='iconDialog'
                alt=''
              />
              <div style={{marginLeft: 14}}>
                <text style={{color: '#ff0066', fontSize: 24, fontWeight: 'bold'}}>
                    {strings.walletCompraPrivada}
                </text>
                <br/>
                <text style={{color: '#cc9900', fontSize: 14, fontWeight: 'bold'}}>
                    {strings.dialogCompraSubtitle}
                </text>
              </div>
            </div>
            <div>           
              <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center', marginTop: 30, marginBottom: 10}}>
                <img
                  src={require('../../images/dialog_vendedor_icon.png')}
                  className='iconDialogSmall'
                  alt=''
                />
                <text style={{color: '#6a6a6a', fontSize: 15, fontWeight: 'bold', marginLeft: 8}}>
                  {strings.dialogCompraVendedor}
                </text>
              </div>
              <Select
                id='vendedor'
                placeholder={strings.dialogCompraVendedor.toLowerCase() + '...'}
                noOptionsMessage={()=> '...'}
                //value={vendedorSelected !== null ? vendedorSelected.label : ''}
                onChange={(value : any)=> setVendedorSelected(value) }
                options={vendedores}
                className='customInput'
                maxMenuHeight={200}
                styles={{
                  control: ({ borderColor, boxShadow, ...provided }, { theme }) => ({
                    ...provided,                    
                    borderColor: theme.colors.neutral20,
                    '&:hover': {
                      borderColor: '#ff0066',
                    }
                  })
                }}
                onBlur={handleBlur('vendedor')}
              />
              {(vendedorSelected === null && touched.vendedor) &&
                <text className='errorTextWallet'>{strings.obligatorio}</text>
              }
              <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center', marginTop: 30, marginBottom: 10}}>
                <img
                  src={require('../../images/dialog_transferir_icon.png')}
                  className='iconDialogSmall'
                  alt=''
                />
                <text style={{color: '#6a6a6a', fontSize: 15, fontWeight: 'bold', marginLeft: 8}}>
                    {strings.dialogCompraImporte}
                </text>
              </div>
              <div style={{marginTop: 16, width: '98%', marginLeft: 4}}>
                <TextField
                  id="importeTransferir"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={handleBlur('importeTransferir')}
                  onChange={handleChange('importeTransferir') }
                  value={ values.importeTransferir }
                  variant="standard"
                  className='customInput'
                />
              </div>
              {(errors.importeTransferir && touched.importeTransferir) &&
                  <text className='errorTextWallet'>{errors.importeTransferir}</text>
              }
              <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center', marginTop: 30, marginBottom: 10}}>
                <img
                  src={require('../../images/dialog_concepto_icon.png')}
                  className='iconDialogSmall'
                  alt=''
                />
                <text style={{color: '#6a6a6a', fontSize: 15, fontWeight: 'bold', marginLeft: 8}}>
                    {strings.dialogReservaConcepto}
                </text>
              </div>
              <div style={{marginTop: 16, width: '98%', marginLeft: 4}}>
                <TextField
                  id="concepto"
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={handleBlur('concepto')}
                  onChange={handleChange('concepto') }
                  value={ values.concepto }
                  variant="standard"
                  className='customInput'
                />                
                {(errors.concepto && touched.concepto) &&
                    <text className='errorTextWallet'>{errors.concepto}</text>
                }
              </div>
            </div>
            {
                btnAvailable ?
                  <Button 
                    style={{borderRadius: 14, width: '100%', height: 45, backgroundColor: '#fc5b9b', marginTop: 30, marginBottom: 24, textAlign: 'center'}}
                    onClick={()=> btnAvailable && handleSubmit()}
                  >
                    <text style={{color: 'white', fontSize: 22, fontWeight: 'bold'}}>
                        {strings.dialogCompraBtn}
                    </text>
                  </Button>
                :
                <div style={{textAlign: 'center'}}>
                  <CircularProgress style={{ height: 45, width: 45, marginTop: 30, marginBottom: 22, color: '#fc5b9b'}} />
                </div>
            }
          </DialogContent>
        </Dialog>
      )}
      </Formik>
    </>
  );
};
