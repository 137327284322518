
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  TextField,
  Button,
  Checkbox,
} from '@mui/material';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { ChevronLeft } from '@mui/icons-material';
import moment from 'moment';
import { Formik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';

import { RootState } from '../../store/store';
import { strings } from '../../language/strings';
import { getFormatCalendario, toastErrorSimple, toastSuccessSimple } from '../../utils/utils';

import './RegisterWidget.css';
import { URL_SIGNUP } from '../../utils/apiConstants';
import { LoaderSpinnerOverlay } from '../Loader/LoaderSpinnerOverlay';
import { apiBeinclub } from '../../api/api';

interface RegisterWidgetProps {   
  setWidget:(widget : number)=>void;  
}

export const RegisterWidget : React.FC<RegisterWidgetProps> = props => {
  const { 
    setWidget,      
  } = props;

  const formikRef = useRef<any>(null);

  const paises = useSelector((state : RootState) => state.resources.paises); 
  const idiomas = useSelector((state : RootState) => state.resources.idiomas); 
  const asesores = useSelector((state : RootState) => state.resources.asesores); 
  
  const [ loadingState, setLoadingState ] = useState(false);

  const [ countries, setCountries ] = useState<DropDown[]>([]);
  const [ paisSelected, setPaisSelected ] = useState({id: '198', label: 'ESPAÑA'});

  const [ idiomasState, setIdiomasState ] = useState<DropDown[]>([]);
  const [ idiomaSelected, setIdiomaSelected ] = useState({id: '4', label: 'ESPAÑOL'});

  const [ asesoresState, setAsesoresState ] = useState<DropDown[]>([]);
  const [ asesorSelected, setAsesorSelected ] = useState({id: '5', label: 'Asesor No Asignado'});
//  const [ asesorError, setAsesorError ] = useState(false);

  const customers = [{id: '0', label: strings.datosSocio}, {id: '1', label: strings.datosInvitado}];
  const [ customer, setCustomer ] = useState({id: '1', label: strings.datosInvitado});

  const [ initialValuesFormik, setInitialValuesFormik ] = useState({
    email: '',
    firstName: '',
    lastName: '',
    birthDate: '',
    codePhone:'+34',
    phone: '',
//  nif: '',
    countryId: null,
    langId: null,
    assessorId: null,
    customer: null,
    term: false,
  });

  const backNavigation = () => {
    //formikRef.current.resetForm();
    setAsesorSelected({id: '5', label: 'Asesor No Asignado'});
//  setAsesorError(false);
    setWidget(0);
  };

  useEffect(() => {
    if (paises !== null && paises !== undefined && paises.length > 0){
        let dataPaisesTransformada : DropDown[] = [];
        //let dataCodesPhoneTransformada : DropDown [] = [];
        paises.forEach((element : Country) => {
            dataPaisesTransformada.push(
                {
                    id: element.id.toString(),
                    label: element.name.toUpperCase(),
                });
                /*dataCodesPhoneTransformada.push(
                {
                    key: element.code.toString(),
                    label: '+' + element.code.toString(),
                });*/
        });
        setCountries(dataPaisesTransformada);
        //setCodesPhones(reorderArrayforKey(dataCodesPhoneTransformada));
    }

    if (idiomas !== null && idiomas !== undefined && idiomas.length > 0){
        let dataIdiomaTransformada : DropDown [] = [];
        idiomas.forEach((element : Language) => {
            dataIdiomaTransformada.push(
                {
                    id: element.id.toString(),
                    label: element.name.toUpperCase(),
                });
        });
        setIdiomasState(dataIdiomaTransformada);
    }

    if (asesores !== null && asesores !== undefined && asesores.length > 0){
        let dataAsesoresTransformada : DropDown[] = [];
        asesores.forEach((element : Advisor) => {
            dataAsesoresTransformada.push(
                {
                    id: element.id.toString(),
                    label: element.username,
                });
        });
        setAsesoresState(dataAsesoresTransformada);
    }
  }, [paises, idiomas, asesores]);

  const datosPersonalesValidationSchema = yup.object().shape({
    email: yup
        .string()
        .email(strings.emailNotValid)
        .required(strings.obligatorio),
    firstName: yup
        .string()
        .required(strings.obligatorio),
    lastName: yup
        .string()
        .required(strings.obligatorio),
    birthDate: yup
        .string()
        .required(strings.obligatorio),
    phone: yup
        .string()
        .required(strings.obligatorio),
    codePhone: yup
        .string()
        .required(strings.obligatorio),
    /*nif: yup
        .string()
        .required(strings.obligatorio),*/
  });

  const handleSubmitClick = (values : any) =>{    
    if (!values.term) {
        toastErrorSimple(strings.aceptarCondiciones);
    /*} else if (asesorSelected === null) {
      //Error: Falta el asesor
      setAsesorError(true);*/
    } else {
        //Esta Ok
        let userBuffer = {
            'email': values.email && values.email.trim().length > 0 && values.email.toString().trim(),
            'login': values.email && values.email.trim().length > 0 && values.email.toString().trim(),
            'firstName': values.firstName && values.firstName.trim().length > 0 && values.firstName.toString().trim(),
            'lastName': values.lastName && values.lastName.trim().length > 0 && values.lastName.toString().trim(),
            'birthDate': values.birthDate !== '' && moment(new Date(values.birthDate._d)).format('YYYY-MM-DD') ,
            //'birthDate': values.birthDate && values.birthDate.length > 0 && (moment(values.birthDate, 'DD-MM-YYYY').format('YYYY-MM-DD')),
            //'phone': values.phone && values.phone.trim().length > 0 && codePhoneSelected.label + values.phone.toString().trim(),
            'phone': values.phone && values.phone.trim().length > 0 && values.codePhone && values.codePhone.trim().length > 0
                      && values.codePhone.toString().trim() + values.phone.toString().trim(),
            //'nif': values.nif && values.nif.trim().length > 0 && values.nif.toString().trim(),
            'nif': '-',
            'countryId': paisSelected !== null ? paisSelected.id : null,
            'langId': idiomaSelected !== null ? idiomaSelected.id : null,
            'assessorId': asesorSelected !== null ? asesorSelected.id : null,
            'customer': customer.id,
        }; 
        //console.log(userBuffer) 
        registerApi(userBuffer);
    }
  };

  const registerApi = (userBuffer : any) => {
    setLoadingState(true);
    apiBeinclub.post(URL_SIGNUP, JSON.stringify(userBuffer))
        .then( (res : any) => {
            //console.log(res);
            if (res.data.status) {
                toastSuccessSimple(strings.toastAlta);
                //setTimeout(function(){
                  setWidget(0)
                //}, 5500);
            } else if (res.data.status === 0) {
                toastErrorSimple(res.data.message.email[0]);               
            }
        }).catch((e : any) =>{
            console.log(e.response.data);
            toastErrorSimple(strings.mal);            
        })
        .finally(()=>setLoadingState(false));
  };

  return ( 
    <Box
      style={{width: 650, textAlign: 'center', margin: 'auto', padding: 32, paddingBottom: 32, backgroundColor: '#FFFFFF40', borderRadius: 16}}
    > 
      <LoaderSpinnerOverlay visible={loadingState}/> 
      <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', position: 'relative'}}>               
        <ChevronLeft style={{fontSize: 50, position: 'absolute', left: -12, top: 4}} onClick={()=> backNavigation()}/> 
        <img
          src={require('../../images/logo_bein.png')}
          style={{width: 210, height:70, marginBottom: 6}}
          alt='' 
        />                                   
      </div> 
      <text style={{
          color: 'white',
          fontWeight: 'bold',
          fontSize: 15,
          marginBottom: 12,
        }}
      >
        {strings.animaEntrar + ', ' + strings.animaPertenecer + ', ' + strings.animaDisfrutar}
      </text>
      <br/>
      <br/>
      <text style={{fontSize: 16, fontWeight: 'bold', marginBottom: 20}}>{strings.registroTitle}</text>
      <Formik
        innerRef={formikRef}
        validationSchema={datosPersonalesValidationSchema}
        enableReinitialize={true}
        initialValues={initialValuesFormik}
        onSubmit={(values : any) =>{ handleSubmitClick(values);}}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors, touched, isValid }) => ( 
          <div>             
            <div style={{textAlign: 'left'}}>
              <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                <div style={{width: '100%'}}>
                  <TextField
                    id='firstName'
                    onBlur={handleBlur('firstName')}
                    onChange={handleChange('firstName')}
                    value={values.firstName}
                    label={strings.nombre}
                    type='text'
                    size='small'
                    className='inputRegister'
                    style={{marginTop: 16}}
                  /> 
                  {(errors.firstName && touched.firstName) &&
                    <text className='errorTextReg'>{errors.firstName}</text>
                  }
                </div>
                <div style={{width: '100%', marginLeft: 12}}>
                  <TextField
                    id='lastName'
                    onBlur={handleBlur('lastName')}
                    onChange={handleChange('lastName')}
                    value={values.lastName}
                    label={strings.apellidos}
                    type='text'
                    size='small'
                    className='inputRegister'
                    style={{marginTop: 16}}
                  /> 
                  {(errors.lastName && touched.lastName) &&
                    <text className='errorTextReg'>{errors.lastName}</text>
                  }
                </div>
              </div>
              <TextField
                id='email'
                onBlur={handleBlur('email')}
                onChange={handleChange('email')}
                value={values.email}
                label={strings.emailValido + strings.asterisco}
                type='email'
                size='small'
                className='inputRegister'
                style={{marginTop: 16}}
              /> 
              {(errors.email && touched.email) &&
                <text className='errorTextReg'>{errors.email}</text>
              }
              <div style={{display: 'flex', flexDirection: 'row', marginTop: 16}}>
                <TextField
                  id='codePhone'
                  onBlur={handleBlur('codePhone')}
                  onChange={handleChange('codePhone')}
                  value={values.codePhone}
                  label={'Cod*'}
                  type='tel'
                  size='small'
                  className='inputRegister'
                  style={{width: '20%'}}
                /> 
                <TextField
                  id='phone'
                  onBlur={handleBlur('phone')}
                  onChange={handleChange('phone')}
                  value={values.phone}
                  label={strings.pcPhone + strings.asterisco}
                  type='tel'
                  size='small'
                  className='inputRegister'
                  style={{width: '79%', marginLeft: '2%'}}
                /> 
              </div>
              <div style={{display: 'flex', flexDirection: 'row', marginTop: 8}}>
                {(errors.codePhone && touched.codePhone) &&
                  <text className='errorTextReg'>{errors.codePhone}</text>
                }
                {(errors.phone && touched.phone) &&
                  <text className='errorTextReg' style={{marginLeft: (errors.codePhone && touched.codePhone) ? 8 : '23%'}}>{errors.phone}</text>
                }
              </div>
              { /* FECHA DE NACIMIENTO */ }
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label={strings.birthDate + strings.asterisco}
                  value={values.birthDate}
                  onChange={(newValue) => formikRef.current.setFieldValue('birthDate', newValue)}
                  format={getFormatCalendario()}
                  //maxDate={new Date()}
                  slotProps={{
                    textField: {
                      size: 'small',
                      onBlur: handleBlur('birthDate'),
                    },
                  }}
                  sx={{width: '100%', marginTop: '10px', marginBottom: (errors.birthDate && touched.birthDate) ? '2px' : '10px' }}                  
                />
              </LocalizationProvider>
              {(errors.birthDate && touched.birthDate) &&
                <text className='errorTextReg' style={{marginTop: -5}}>{errors.birthDate}</text>
              }
              <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginTop: 8}}>
                <div style={{width: '100%'}}>
                <Select
                  id='countryId'
                  placeholder={strings.registroPaisResidencia}
                  noOptionsMessage={()=> '...'}                  
                  onChange={(value : any)=> setPaisSelected(value)}
                  options={countries}
                  defaultValue={paisSelected}
                  className='inputRegister'
                  maxMenuHeight={200}
                  styles={{
                      control: ({ borderColor, boxShadow, ...provided }, { theme }) => ({
                          ...provided, 
                          backgroundColor: 'transparent',                  
                          borderColor: '#C4979B',
                          '&:hover': {
                              borderColor: '#ff0066',
                          }
                      }),
                      menu: (defaultStyles: any) => ({
                        ...defaultStyles,
                        backgroundColor: 'white',
                      })
                  }}
                  onBlur={handleBlur('countryId')}
                />
              </div>
              <div style={{width: '100%', marginLeft: 12}}>
                <Select
                  id='langId'
                  placeholder={strings.idioma + strings.asterisco}
                  noOptionsMessage={()=> '...'}                  
                  onChange={(value : any)=> setIdiomaSelected(value)}
                  options={idiomasState}
                  defaultValue={idiomaSelected}
                  className='inputRegister'
                  maxMenuHeight={200}
                  styles={{
                      control: ({ borderColor, boxShadow, ...provided }, { theme }) => ({
                          ...provided, 
                          backgroundColor: 'transparent',                  
                          borderColor: '#C4979B',
                          '&:hover': {
                              borderColor: '#ff0066',
                          }
                      }),
                      menu: (defaultStyles: any) => ({
                        ...defaultStyles,
                        backgroundColor: 'white',
                      })
                  }}
                  onBlur={handleBlur('langId')}
                />      
              </div>     
            </div>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
              <div style={{width: '100%'}}>
                <Select
                  id='assessorId'
                  placeholder={strings.asesor + strings.asterisco}
                  noOptionsMessage={()=> '...'}                  
                  onChange={(value : any)=> setAsesorSelected(value)}
                  options={asesoresState}
                  defaultValue={asesorSelected}
                  className='inputRegister'
                  maxMenuHeight={200}
                  styles={{
                      control: ({ borderColor, boxShadow, ...provided }, { theme }) => ({
                          ...provided, 
                          backgroundColor: 'transparent',
                          marginTop: 16,                   
                          borderColor: '#C4979B',
                          '&:hover': {
                              borderColor: '#ff0066',
                          }
                      }),
                      menu: (defaultStyles: any) => ({
                        ...defaultStyles,
                        backgroundColor: 'white',
                      })
                  }}
                  onBlur={handleBlur('assessorId')}
                />   
              </div>
              <div style={{width: '100%', marginLeft: 12}}>           
                <Select
                  id='customer'
                  placeholder={strings.datosTipoUsuario + strings.asterisco}
                  noOptionsMessage={()=> '...'}                  
                  onChange={(value : any)=> setCustomer(value)}
                  options={customers}
                  defaultValue={customer}
                  className='inputRegister'
                  maxMenuHeight={200}
                  styles={{
                      control: ({ borderColor, boxShadow, ...provided }, { theme }) => ({
                          ...provided, 
                          backgroundColor: 'transparent',
                          marginTop: 16,                   
                          borderColor: '#C4979B',
                          '&:hover': {
                              borderColor: '#ff0066',
                          }
                      }),
                      menu: (defaultStyles: any) => ({
                        ...defaultStyles,
                        backgroundColor: 'white',
                      })
                  }}
                  onBlur={handleBlur('customer')}
                />
                </div>
              </div>
              <div style={{marginTop: 24, display: 'flex', flexDirection: 'row', height: 20}}>
                <Checkbox color="primary" onChange={() => {values.term = !values.term;}} style={{marginLeft: -10, marginRight: -6}} />
                <div style={{justifyContent: 'center', height: 20}}>
                  <text style={{fontSize: 12}}>{strings.condicionesCheckBox}</text>
                  <text style={{cursor: 'pointer', fontSize: 12, color: '#ff0066'}} onClick={()=> window.open(strings.condicionesURL)}>{strings.condiciones}</text>
                  <text style={{fontSize: 12}}>{strings.condicionesCheckBox2}</text>
                  <text style={{cursor: 'pointer', fontSize: 12, color: '#ff0066'}} onClick={()=> window.open(strings.avisoLegalURL)}>{strings.politica}</text>
                </div>
              </div>              
            </div>      
            <div>
              <Button
                variant="contained"
                color="primary"
                size='small'
                className='btnRegister' 
                onClick={()=> handleSubmit()} 
                disabled={loadingState}
                style={{marginTop: 24, fontWeight: 'bold'}}      
              >
                {strings.registerButton}
              </Button>
            </div>
          </div>   
        )}   
      </Formik>   
    </Box>
  );
}  
