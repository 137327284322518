import LocalizedStrings from 'react-localization';

import en from './english.json';
import es from './espanol.json';
import fr from './frances.json';
import ca from './catalan.json';
import tr from './turco.json';
import ro from './rumano.json';
import it from './italiano.json';
import de from './aleman.json';

export const strings = new LocalizedStrings({
    en,
    es,
    fr,
    ca,
    tr,
    ro,
    it,
    de,     
});
