import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Dialog,
    DialogContent,
    CircularProgress,
    Button,
    TextField,
  } from '@mui/material';

import { Formik } from 'formik';
import copy from 'copy-to-clipboard';

import { RootState } from '../../store/store';
import { apiBeinclub } from '../../api/api';
import { toastSuccessSimple } from '../../utils/utils';
import { strings } from '../../language/strings';
import { URL_CONVERT_IC } from '../../utils/apiConstants';
import { SubDialogRecargarDinero } from './SubDialogRecargarDinero';

interface DialogRecargarProps {
    dialogRecargarVisible: boolean;
    setDialogRecargarVisible: (arg0: boolean) => void;
}

export const DialogRecargar : React.FC<DialogRecargarProps> = props => {
    const {
        dialogRecargarVisible,
        setDialogRecargarVisible,
    } = props;

    const user : User | null = useSelector((state : RootState) => state.user.currentUser);

    const [ dialogRecargarDineroVisible, setDialogRecargarDineroVisible ] = useState(false);
    const [ dialogTransferencia, setDialogTransferencia ] = useState(false);
    const [ dialogSimulador, setDialogSimulador ] = useState(false);
    const [ modoIC, setModoIC ] = useState(false);
    const [ totalIC, setTotalIC ] = useState(0);
    const [ loadingState, setLoadingState ] = useState(false);

    const [ datosTransferencia, setDatosTransferencia ] = useState<any>(undefined);

    useEffect(() => {
        if (!datosTransferencia) {
            fetch('https://static.vipcard.group/beinclub/beinclub.json')
            .then(response => {
                response.json().then( data =>{
                    setDatosTransferencia(data);
                });
            })
            .catch(error => {
                console.error(error);
            });
        }
    }, [datosTransferencia]);

    const showModalTransferencia = () => {
        setDialogRecargarVisible(false);
        setDialogTransferencia(true);
    };

    const formikRefSimulator = useRef<any | null>(null);

    const [ initialValuesFormikSimulatorIC, setInitialValuesFormikSimulatorIC ] = useState({
        importeRecarga: '',        
    });

    const handleCalculate = (values : any) =>{
        if (values.importeRecarga && values.importeRecarga > 0) {
            setModoIC(false);
            setLoadingState(true);
            apiBeinclub.get(URL_CONVERT_IC + values.importeRecarga)            
            .then( res => {
                //console.log(res);
                if (res.data.status === 1) {
                    setTotalIC(res.data.ic);
                    setModoIC(true);
                }
            }).catch((e) =>{
                console.log(e);
            }).finally(()=> setLoadingState(false));
        }
    };

    const recargarTranferenciaDialog = () => {
        return (
            <Dialog
            open={dialogTransferencia}
            onClose={() => setDialogTransferencia(false)}
            >
                <DialogContent sx={{width: '400px'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 4}}>
                        <img
                            src={require('../../images/dialog_recargar_icon.png')}
                            className='iconDialog'
                            alt=''
                        />
                        <div style={{marginLeft: 14}}>
                            <text style={{color: '#ff0066', fontSize: 24, fontWeight: 'bold'}}>
                                {strings.walletRecargar}
                            </text>
                            <br/>
                            <text style={{color: '#cc9900', fontSize: 14, fontWeight: 'bold'}}>
                                {strings.dialogRecargarbtnTransferencia}
                            </text>
                        </div>
                    </div>
                    <div style={{marginTop: 32, textAlign: 'center'}}>
                        <text style={{color: 'black', fontSize: 17, fontWeight: 'bold', textAlign: 'center', marginTop: 28}}>
                            {datosTransferencia && datosTransferencia.n}
                        </text> 
                        <br/>       
                        <text style={{color: '#272727', fontSize: 13, textAlign: 'center', marginTop: 24}}>
                            {strings.sucursal + (datosTransferencia && datosTransferencia.b)}
                        </text>
                        <br/>     
                        <br/>     
                        <text style={{color: '#ff0066', fontSize: 14, fontWeight: 'bold', textAlign: 'center', marginTop: 16}}>
                            {strings.cuenta + (datosTransferencia && datosTransferencia.c)}
                        </text>
                        <br/>     
                        <br/>     
                        <text style={{color: '#272727', fontSize: 13, textAlign: 'center', marginTop: 16}}>
                            {strings.domicilio + (datosTransferencia && datosTransferencia.a)}
                        </text>
                        <br/>     
                        <br/>     
                        <text style={{color: '#ff0066', fontSize: 16, fontWeight: 'bold', textAlign: 'center', marginTop: 24}}>
                            {'CONCEPTO: ' + (user && user.user_name)}
                        </text>
                    </div>
                    <Button 
                        style={{borderRadius: 14, width: '100%', height: 45, backgroundColor: '#fc5b9b', marginTop: 30, marginBottom: 24, textAlign: 'center'}}
                        onClick={()=> {
                            setDialogTransferencia(false);
                            copy(datosTransferencia ? datosTransferencia.c : '');
                            toastSuccessSimple(strings.dialogTransferenciaToast); 
                        }}
                    >
                        <text style={{color: 'white', fontSize: 18, fontWeight: 'bold'}}>
                            {strings.dialogTransferenciaCopiar}
                        </text>
                    </Button>
                </DialogContent>
            </Dialog>
        );
    };

    const simuladorICDialog = () => {
        return (
            <Formik
                innerRef={formikRefSimulator}               
                enableReinitialize={true}
                initialValues={initialValuesFormikSimulatorIC}
                onSubmit={(values) => handleCalculate(values)}
            >
            {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                <Dialog
                    open={dialogSimulador}
                    onClose={() => {
                        setDialogSimulador(false);
                        setTotalIC(0);
                        setModoIC(false);
                        formikRefSimulator.current!.resetForm();
                    }}
                >
                    <DialogContent sx={{width: '400px'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 4}}>
                            <div style={{display: 'flex', backgroundColor: '#ff0066', width: 60, height: 60, borderRadius: 30, justifyContent: 'center', alignItems: 'center'}}>
                                <text style={{color: 'white', fontSize: 35, fontWeight: 'bold'}}>
                                    IC
                                </text>
                            </div>
                            <div style={{marginLeft: 14}}>
                                <text style={{color: '#ff0066', fontSize: 22, fontWeight: 'bold'}}>
                                    {strings.icButton}
                                </text>
                                <br/>
                                <text style={{color: '#cc9900', fontSize: 14, fontWeight: 'bold'}}>
                                    {strings.icSubtitle}
                                </text>
                            </div>
                        </div>
                        <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center', marginTop: 30, marginBottom: 10}}>
                            <img
                                src={require('../../images/dialog_importe_icon.png')}
                                className='iconDialogSmall'
                                alt=''
                            />
                            <text style={{color: '#6a6a6a', fontSize: 15, fontWeight: 'bold', marginLeft: 8}}>
                                {strings.dialogRecargaImporte + ' (€)'}
                            </text>
                        </div>
                        <div style={{marginTop: 16, width: '98%', marginLeft: 4}}>
                            <TextField
                                id="importeRecarga"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={handleBlur('importeRecarga')}
                                onChange={handleChange('importeRecarga')}
                                value={ values.importeRecarga }
                                variant="standard"
                                className='customInput'
                            />
                        </div>
                        {(errors.importeRecarga && touched.importeRecarga) &&
                            <text className='errortext'>{errors.importeRecarga}</text>
                        }
                        {
                            modoIC &&
                            <div style={{alignItems: 'center', marginTop: 36, marginBottom: 10}}>
                                <text style={{color: '#fc5b9b', fontSize: 20, fontWeight: 'bold'}}>
                                    {strings.icTotal + totalIC}
                                </text>
                            </div>
                        }
                        {
                            !loadingState ?
                            <Button 
                                style={{borderRadius: 14, width: '100%', height: 45, backgroundColor: '#fc5b9b', marginTop: 36, textAlign: 'center'}}
                                onClick={()=> !loadingState && handleSubmit()}
                            >
                                <text style={{color: 'white', fontSize: 22, fontWeight: 'bold'}}>
                                    {strings.calcularButton}
                                </text>
                            </Button>
                            :
                            <div style={{textAlign: 'center'}}>
                                <CircularProgress style={{ height: 45, width: 45, marginTop: 30, marginBottom: 22, color: '#fc5b9b'}} />
                            </div>
                        }
                        { 
                            !loadingState ?
                            <Button 
                                style={{borderRadius: 14, width: '100%', height: 45, backgroundColor: '#cc9900', marginTop: 16, marginBottom: 20, textAlign: 'center'}}
                                onClick={()=> {
                                    setModoIC(false); 
                                    formikRefSimulator.current!.resetForm(); 
                                    setTotalIC(0);
                                }}
                            >
                                <text style={{color: 'white', fontSize: 22, fontWeight: 'bold'}}>
                                {strings.resetButton}
                                </text>
                            </Button>
                            :
                            <div style={{height: 45, width: 45, marginTop: 30, marginBottom: 55}}/>
                        }
                    </DialogContent>
                </Dialog>
           )}
           </Formik>
        );
    };

    return (
        <>      
            { recargarTranferenciaDialog() }      
            { simuladorICDialog() }    
            <SubDialogRecargarDinero
                setDialogRecargarDineroVisible={setDialogRecargarDineroVisible}
                dialogRecargarDineroVisible={dialogRecargarDineroVisible}  
            />
            <Dialog
                open={dialogRecargarVisible}
                onClose={() => setDialogRecargarVisible(false)}
            >
                <DialogContent sx={{width: '400px'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 4}}>
                        <img
                            src={require('../../images/dialog_recargar_icon.png')}
                            className='iconDialog'
                            alt=''
                        />
                        <div style={{marginLeft: 14}}>
                            <text style={{color: '#ff0066', fontSize: 24, fontWeight: 'bold'}}>
                                {strings.walletRecargar}
                            </text>
                            <br/>
                            <text style={{color: '#cc9900', fontSize: 14, fontWeight: 'bold'}}>
                                {strings.dialogRecargarSubtitle}
                            </text>
                            <br/>
                            <text style={{color: '#6a6a6a', fontSize: 12, fontWeight: 'bold'}}>
                                {strings.dialogRecargartext}
                            </text>
                        </div>
                    </div>
                    <div>
                        <Button 
                            style={{borderRadius: 14, width: '100%', height: 45, backgroundColor: '#fc5b9b', marginTop: 40, textAlign: 'center'}}
                            onClick={()=> {
                                    setDialogRecargarVisible(false);
                                    setDialogRecargarDineroVisible(true);
                                }}
                            >
                            <text style={{color: 'white', fontSize: 20, fontWeight: 'bold'}}>
                                {strings.dialogRecargarbtnTarjeta}
                            </text>
                        </Button>
                        <Button 
                            style={{borderRadius: 14, width: '100%', height: 45, backgroundColor: '#fc5b9b', marginTop: 16, textAlign: 'center'}}
                            onClick={()=> 
                                    showModalTransferencia()
                                }
                            >
                            <text style={{color: 'white', fontSize: 20, fontWeight: 'bold'}}>
                                {strings.dialogRecargarbtnTransferencia}
                            </text>
                        </Button>
                        <Button 
                            style={{borderRadius: 14, width: '100%', height: 45, backgroundColor: '#fc5b9b', marginTop: 16, marginBottom: 24, textAlign: 'center'}}
                            onClick={()=> {
                                    setDialogRecargarVisible(false);
                                    setDialogSimulador(true);
                                }}
                            >
                            <text style={{color: 'white', fontSize: 20, fontWeight: 'bold'}}>
                                {strings.icButton}
                            </text>
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};
