import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '../../store/store';
import { LoaderSpinnerOverlay } from '../../components/Loader/LoaderSpinnerOverlay';

import './LoginPage.css'
import { LoginWidget } from '../../components/LoginWidget/LoginWidget';
import { RegisterWidget } from '../../components/RegisterWidget/RegisterWidget';
import { RecoverWidget } from '../../components/RecoverPassword/RecoverWidget';

interface LoginPageProps {
  //ejemplo?: string;
}

export const LoginPage : React.FC<LoginPageProps> = props => {
  /*const {
    ejemplo,
  } = props;*/

  const navigate = useNavigate();

  const loader = useSelector((state : RootState) => state.loading.loading);
  const token = useSelector((state : RootState) => state.auth.token); 

  const [widget, setWidget] = useState<number>(0);  //0: Login, 1:Register, 2: Cambio de Password

  useEffect(() => {
    if (token) {
      navigate('/home')
    }
  }, [token, navigate]);
  
  return ( 
    <div className='contentLogin'>
      <LoaderSpinnerOverlay visible={loader} paddingBottom={80}/> 
      {
        widget === 0 ?
          <LoginWidget setWidget={setWidget} />
          :
          (
            widget === 1 ?
            <RegisterWidget setWidget={setWidget} />
            :
            <RecoverWidget setWidget={setWidget} />
          )          
      }      
    </div>
  );
}  
