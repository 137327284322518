import axios from 'axios';

import { APPLICATION_ID, URL_BEINCLUB, URL_SERVIDOR, USER_TOKEN } from '../utils/apiConstants';

//const phoneLanguage = getPhoneLanguage();

export const api = axios.create({
    baseURL: URL_SERVIDOR,
    timeout: 5000,
    headers: {
        'APPID': APPLICATION_ID,
        'Agent': 'react',
        //'Accept-Language': phoneLanguage,
        'Content-Type': 'application/json',
    },
});

export const apiSecured = axios.create({
    baseURL: URL_SERVIDOR,
    timeout: 5000,
});

/*apiSecured.interceptors.request.use(
    async config => {
        const token = getLocalPreference(USER_TOKEN, Types.String);
        const bearerToken = 'Bearer ' + token;
        config.headers.Authorization = bearerToken;
        config.headers.APPID = APPLICATION_ID;
        config.headers.Agent = 'react';
     config.headers['Accept-Language'] = phoneLanguage;
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => Promise.reject(error),
);*/

export const apiBeinclubUnsecured = axios.create({
    baseURL: URL_BEINCLUB,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
    },
});

apiBeinclubUnsecured.interceptors.request.use(
    async config => {     
        return config;
    },
    error => Promise.reject(error),
);

export const apiBeinclub = axios.create({
    baseURL: URL_BEINCLUB,
    timeout: 5000,
    /*headers: {
        'APPID': APPLICATION_ID,
        'Agent': 'react',
        'Accept-Language': phoneLanguage,
        'Content-Type': 'application/json',
    },*/
});

apiBeinclub.interceptors.request.use(
    async config => {
        const token = localStorage.getItem(USER_TOKEN);
        //const token = 'eyJpdiI6Inp4MHdUbmJON2V3ZExRZXBQNVU4amc9PSIsInZhbHVlIjoiVUJka0VMRWQ2T0lLWHV6Z0dQRTBLTG52aDVackVZQW9DQm1MbkZBVVM3QXBJYnVmRW03bWhSQ24wNzhLdWdVRVg1TnNXR3BxcUszQ1dxS2lCR2ZzaFFiM3FnQUs1ck5iR0tvK3JnOE96Sk9YU2laRFZxLzZtR3VQcytzRmlWY3IiLCJtYWMiOiJlNGVmYmQwYWVmMDJiOWFhZDQzZjE2YjM2NTVmZGZmNGMwYzgwZTU4NjMwNWQ4NmM4NDQ2ZjQxMGNmMWY4ODZlIn0%3D';
        const bearerToken = 'Bearer ' + token;
        config.headers.Authorization = bearerToken;
        config.headers.APPID = APPLICATION_ID;
        config.headers.Agent = 'react';
     //   config.headers['Accept-Language'] = phoneLanguage;
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => Promise.reject(error),
);
