import { createBrowserRouter } from 'react-router-dom';

import { LayoutPage } from '../pages/layout/LayoutPage';
import { LoginPage } from '../pages/login/LoginPage';
import { HomePage } from '../pages/home/HomePage';
import { PerfilPage } from '../pages/Perfil/PerfilPage';


export const router = createBrowserRouter([
        { 
            path: "/",
            element: <LayoutPage />,
            //loader: layoutLoader,
        },
        { 
            path: "/login",
            element: <LoginPage />,
        },
        { 
            path: "/home",
            element: <HomePage />,
        },
        { 
            path: "/profile",
            element: <PerfilPage />,
        },
        /*{ 
            path: "descarga/",
            element: <RootPage />,        
            children: [
                //Todas las Pages con SideBar
                {
                    path: "facturas",
                    element: <FacturasPage />,
                    //loader: teamLoader,
                },
                {
                    path: "pods",
                    element: <PodsPage />,
                },
                {
                    path: "ficheros_zonas",
                    element: <FicherosZonasPage />,
                },
                {
                    path: "ficheros",
                    element: <FicherosCargaPage />,
                },
            ],
        },*/
    ],
    {
        //basename: '/descarga'    for subdirectory
    }
);
