import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
    Dialog,
    DialogContent,
    Button,
    TextField,
  } from '@mui/material';

import { Formik } from 'formik';
import * as yup from 'yup';

import { RootState } from '../../store/store';
import { strings } from '../../language/strings';
import { URL_REDSYS, URL_REDSYS_CONCEPTO} from '../../utils/apiConstants';

interface SubDialogRecargarDineroProps {
    dialogRecargarDineroVisible: boolean;
    setDialogRecargarDineroVisible: (arg0: boolean) => void;
    concepto?: string;
}

export const SubDialogRecargarDinero : React.FC<SubDialogRecargarDineroProps> = props => {
    const {
        dialogRecargarDineroVisible,
        setDialogRecargarDineroVisible,
        concepto = '',
    } = props;

    const formikRefRecargar = useRef<any | null>(null);

    const user : User | null = useSelector((state : RootState) => state.user.currentUser);

    const [ initialValuesFormik, setInitialValuesFormik ] = useState({
        importeRecarga: '',
        conceptoRecarga: concepto,
    });

    const dialogRecargaValidationSchema = yup.object().shape({
        importeRecarga: yup
            .string()
            .required(strings.obligatorio)
            /*.test(
                '',
                strings.sinDecimales,
                (value) => value !== undefined && !value!.includes('.') && !value!.includes(','),
              )*/,
        conceptoRecarga: yup
            .string()
            .required(strings.obligatorio),
    });

    const formarURLRedsys = (importeRecarga : string , conceptoRecarga : string) => {
        const urlRedsys = URL_REDSYS + importeRecarga.toString().trim() + URL_REDSYS_CONCEPTO + conceptoRecarga.trim().replaceAll(' ', '%20') + '&name=' + user!.name.trim().replaceAll(' ', '%20') + '&id=' + user!.id;
        window.open(urlRedsys);
    };

    const handleSubmitClick = (values : any) =>{
        formarURLRedsys(values.importeRecarga, values.conceptoRecarga);
        setDialogRecargarDineroVisible(false);
        formikRefRecargar.current!.resetForm();
    };

    return (
        <Formik
            innerRef={formikRefRecargar}
            validationSchema={dialogRecargaValidationSchema}
            enableReinitialize={true}
            initialValues={initialValuesFormik}
            onSubmit={(values) =>{ handleSubmitClick(values); }}
        >
        {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
            <Dialog
                open={dialogRecargarDineroVisible}
                onClose={() => {
                    setDialogRecargarDineroVisible(false);
                    formikRefRecargar.current!.resetForm();
                }}
            >
                <DialogContent sx={{width: '400px'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 4}}>
                        <img
                            src={require('../../images/dialog_recargar_icon.png')}
                            className='iconDialog'
                            alt=''
                        />
                        <div style={{marginLeft: 14}}>
                            <text style={{color: '#ff0066', fontSize: 24, fontWeight: 'bold'}}>
                                {strings.walletRecargar}
                            </text>
                            <br/>
                            <text style={{color: '#cc9900', fontSize: 14, fontWeight: 'bold'}}>
                                {strings.dialogRecargarbtnTarjeta}
                            </text>
                        </div>
                    </div>
                    <div>
                        <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center', marginTop: 30, marginBottom: 10}}>
                            <img
                                src={require('../../images/dialog_importe_icon.png')}
                                className='iconDialogSmall'
                                alt=''
                            />
                            <text style={{color: '#6a6a6a', fontSize: 15, fontWeight: 'bold', marginLeft: 8}}>
                                {strings.dialogRecargaImporte}
                            </text>
                        </div>
                        <div style={{marginTop: 16, width: '98%', marginLeft: 4}}>
                            <TextField
                                id="importeRecarga"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={handleBlur('importeRecarga')}
                                onChange={handleChange('importeRecarga') }
                                value={ values.importeRecarga }
                                variant="standard"
                                className='customInput'
                            />
                        </div>
                        {(errors.importeRecarga && touched.importeRecarga) &&
                            <text className='errortext'>{errors.importeRecarga}</text>
                        }
                        <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center', marginTop: 30, marginBottom: 10}}>
                            <img
                                src={require('../../images/dialog_concepto_icon.png')}
                                className='iconDialogSmall'
                                alt=''
                            />
                            <text style={{color: '#6a6a6a', fontSize: 15, fontWeight: 'bold', marginLeft: 8}}>
                                {strings.dialogReservaConcepto}
                            </text>
                        </div>
                        <div style={{marginTop: 16, width: '98%', marginLeft: 4}}>
                            <TextField
                                id="conceptoRecarga"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={handleBlur('conceptoRecarga')}
                                onChange={handleChange('conceptoRecarga') }
                                value={ values.conceptoRecarga }
                                variant="standard"
                                className='customInput'
                            />                
                            {(errors.conceptoRecarga && touched.conceptoRecarga) &&
                                <text className='errortext'>{errors.conceptoRecarga}</text>
                            }
                        </div>
                    </div>
                    <Button 
                        style={{borderRadius: 14, width: '100%', height: 45, backgroundColor: '#fc5b9b', marginTop: 30, marginBottom: 24, textAlign: 'center'}}
                        onClick={()=> handleSubmit()}
                    >
                        <text style={{color: 'white', fontSize: 22, fontWeight: 'bold'}}>
                            {strings.walletRecargar}
                        </text>
                    </Button>
                </DialogContent>
            </Dialog>
        )}
       </Formik>
    );
};
