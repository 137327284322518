import { createSlice } from '@reduxjs/toolkit';

import { apiBeinclubUnsecured, api } from '../api/api';
import { AppDispatch } from '../store/store';
import { URL_ASESORES, URL_CATEGORIAS_BEIN, URL_IDIOMAS, URL_STATIC_ZONE_CATEGORIES, URL_TODOS_PAISES } from '../utils/apiConstants';
import { reorderArrayforId } from '../utils/utils';

export interface ResourcesBeinState {
  zonas: Zone[],
  paises: Country[],
  idiomas: Language[],
  asesores: Advisor[],
  categorias: Category[],
};

const initialState: ResourcesBeinState = {
  zonas: [],
  paises: [],
  idiomas: [],
  asesores: [],
  categorias: [],
};

export const resourcesBeinSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    getZonas: (state, action) => {
      state.zonas = action.payload;
    },
    getPaisesAndPhoneCode: (state, action) => {
      state.paises = action.payload;
    },
    getIdiomas: (state, action) => {
      state.idiomas = action.payload;
    },
    getAsesores: (state, action) => {
      state.asesores = action.payload;
    },
    getCategorias: (state, action) => {
      state.categorias = action.payload;
    },
    resetResources: (state) => {
      state.zonas = [];
      state.paises = [];
      state.idiomas = [];
      state.asesores = [];
      state.categorias = [];
    },
  },
});

const getZonasRest = () => async (dispatch : AppDispatch) => {  
  //fetch(URL_STATIC_ZONE_CATEGORIES)
  apiBeinclubUnsecured.get(URL_STATIC_ZONE_CATEGORIES)
    .then( res => {
      const todosLosPaises = res.data.data;
      const paisesPublicados = todosLosPaises.filter(function( pais : any ) {
          return pais.status !== 'draft';
      });
      reorderArrayforId(paisesPublicados);
      dispatch(getZonas(paisesPublicados));
    }).catch((e) =>{
      console.log(e);
    });
};

export const getPaisesAndPhoneCodeRest = () => async (dispatch : AppDispatch) => {  
  apiBeinclubUnsecured.get(URL_TODOS_PAISES)
    .then( res => {      
        dispatch(getPaisesAndPhoneCode(res.data.data));
    }).catch((e) =>{
        console.log(e);
    });
};

export const getIdiomasRest = () => async (dispatch : AppDispatch) => {  
  apiBeinclubUnsecured.get(URL_IDIOMAS)
    .then( res => {
        dispatch(getIdiomas(res.data.data));
    }).catch((e) =>{
        console.log(e);
    });
};

export const getAsesoresRest = () => async (dispatch : AppDispatch) => {  
  apiBeinclubUnsecured.get(URL_ASESORES)
  .then( res => {         
      dispatch(getAsesores(res.data.data));
  }).catch((e) =>{
      console.log(e);
  });
};

export const getCategoriesBeinRest = ()  => async (dispatch : AppDispatch) => { 
  apiBeinclubUnsecured.get(URL_CATEGORIAS_BEIN)
    .then( res => {
        //setLocalPreference(LAST_RECOVER_CATEGORIES, new Date().getTime().toString());
        dispatch(getCategorias(res.data.data));
    }).catch((e) =>{
        console.log(e);
    });
};

export const getResourcesBein = () => (dispatch : AppDispatch) => {  
  dispatch(getPaisesAndPhoneCodeRest());
  dispatch(getZonasRest());
  dispatch(getIdiomasRest());
  dispatch(getAsesoresRest()); 
  dispatch(getCategoriesBeinRest());  
};

export const restetearRecursosBein = () => async (dispatch : AppDispatch) => { 
  //LIMPIAR TODO
  dispatch(resetResources());  
};

export const { getIdiomas, getAsesores, getCategorias, getPaisesAndPhoneCode, getZonas, resetResources } = resourcesBeinSlice.actions;

export default resourcesBeinSlice.reducer;
