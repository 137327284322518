import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Button,
  TextField,
} from '@mui/material';

import { Formik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';

import { AppDispatch, RootState } from '../../store/store';
import { apiBeinclub } from '../../api/api';
import { toastErrorSimple, toastSuccessSimple } from '../../utils/utils';
import { strings } from '../../language/strings';
import { URL_CATEGORIA_ATRIBUTOS, URL_RESERVA_O_COMPRA_PRIVADA, URL_VENDEDOR } from '../../utils/apiConstants';
import { getUserRest } from '../../redux/userSlice';

interface DialogReservarProps {
    dialogReservarVisible: boolean;
    setDialogReservarVisible: (arg0: boolean) => void;
}

export const DialogReservar : React.FC<DialogReservarProps> = props => {
    const {
        dialogReservarVisible,
        setDialogReservarVisible,
    } = props;

    const dispatch = useDispatch<AppDispatch>();
 
    const user : User | null = useSelector((state : RootState) => state.user.currentUser);

    const [ btnAvailable, setBtnAvailable ] = useState(true);
    const [ categorias, setCategorias ] = useState<DropDown[]>([]);
    const [ categoriaSelected, setCategoriaSelected ] =  useState<any | null>(null);

    const formikRefReservar = useRef<any | null>(null);

    const [ initialValuesFormik, setInitialValuesFormik ] = useState({
        importeReserva: '',
        categoriaReserva: null,
        conceptoReserva: '',
    });

    const dialogReservaValidationSchema = yup.object().shape({
        importeReserva: yup
            .string()
            .required(strings.obligatorio)
            .test(
                '',
                strings.sinDecimales,
                (value) => value !== undefined && !value!.includes('.') && !value!.includes(','),
              ),
        conceptoReserva: yup
            .string()
            .required(strings.obligatorio),
    });

    useEffect(() => {
        apiBeinclub.get(URL_CATEGORIA_ATRIBUTOS)
        .then( res => {
            if (res.data.data[0] !== null){
                let dataCategoriasTransformada : DropDown [] = [];
                res.data.data.forEach((element : Attribute) => {
                    dataCategoriasTransformada.push(
                        {
                            id: element.id.toString(),
                            label: element.name,
                        });
                });
                setCategorias(dataCategoriasTransformada);
            }
        }).catch((e) =>{
            console.log(e);
        });
    }, []);

    const resetBack = () => {
        setCategoriaSelected(null);
        formikRefReservar.current!.resetForm();
        setDialogReservarVisible(false);
    };

    const handleSubmitClick = (values : any) =>{
        if (categoriaSelected !== null) {
            if (user!.balance && (parseInt(user!.balance) < parseInt(values.importeReserva))) {
                toastErrorSimple(strings.faltaInnercoins);
            } else {
                setBtnAvailable(false);
                const parametros = {
                    'price': values.importeReserva,
                    'recipient_user': 1, //Para Reserva es un ID Fijo
                    'attribute_category': categoriaSelected.id,
                    'payment_concept': values.conceptoReserva,
                };
                console.log(parametros)

                apiBeinclub.post(URL_RESERVA_O_COMPRA_PRIVADA, JSON.stringify(parametros))
                .then( res => {
                    //console.log(res);
                    dispatch(getUserRest());
                    resetBack();
                    toastSuccessSimple(strings.reservaRealizada);
                }).catch((e) =>{
                    console.log(e);
                    toastErrorSimple(strings.mal);
                }).finally(()=> setBtnAvailable(true));
            }
        } 
    };

    return (
        <>
            <Formik
                innerRef={formikRefReservar}
                validationSchema={dialogReservaValidationSchema}
                enableReinitialize={true}
                initialValues={initialValuesFormik}
                onSubmit={(values : any) =>{ handleSubmitClick(values); }}
            >
            {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                <Dialog
                    open={dialogReservarVisible}
                    onClose={resetBack}  
                >
                    <DialogContent sx={{width: '400px'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 4}}>
                            <img
                                src={require('../../images/dialog_reserva_icon.png')}
                                className='iconDialog'
                                alt=''
                            />
                            <div style={{marginLeft: 14}}>
                                <text style={{color: '#ff0066', fontSize: 24, fontWeight: 'bold'}}>
                                    {strings.walletReservar}
                                </text>
                                <br/>
                                <text style={{color: '#cc9900', fontSize: 14, fontWeight: 'bold'}}>
                                    {strings.dialogReservaSubtitle}
                                </text>
                            </div>
                        </div>
                        <div>
                            <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center', marginTop: 30, marginBottom: 10}}>
                                <img
                                src={require('../../images/dialog_importe_icon.png')}
                                className='iconDialogSmall'
                                alt=''
                                />
                                <text style={{color: '#6a6a6a', fontSize: 15, fontWeight: 'bold', marginLeft: 8}}>
                                    {strings.dialogCompraImporte}
                                </text>
                            </div>
                            <div style={{marginTop: 16, width: '98%', marginLeft: 4}}>
                                <TextField
                                    id="importeReserva"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={handleBlur('importeReserva')}
                                    onChange={handleChange('importeReserva') }
                                    value={ values.importeReserva }
                                    variant="standard"
                                    className='customInput'
                                />
                            </div>
                            {(errors.importeReserva && touched.importeReserva) &&
                                <text className='errorTextWallet'>{errors.importeReserva}</text>
                            }
                            <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center', marginTop: 30, marginBottom: 10}}>
                                <img
                                    src={require('../../images/dialog_categoria_icon.png')}
                                    className='iconDialogSmall'
                                    alt=''
                                />
                                <text style={{color: '#6a6a6a', fontSize: 15, fontWeight: 'bold', marginLeft: 8}}>
                                    {strings.dialogReservaCategoria}
                                </text>
                            </div>
                            <Select
                                id='categoriaReserva'
                                placeholder={strings.dialogReservaCategoria.toLowerCase() + '...'}
                                noOptionsMessage={()=> '...'}
                                //value={categoriaSelected !== null ? categoriaSelected.label : ''}
                                onChange={(value : any)=> setCategoriaSelected(value)}
                                options={categorias}
                                className='customInput'
                                maxMenuHeight={200}
                                styles={{
                                    control: ({ borderColor, boxShadow, ...provided }, { theme }) => ({
                                            ...provided,                    
                                            borderColor: theme.colors.neutral20,
                                            '&:hover': {
                                                borderColor: '#ff0066',
                                            }
                                        })
                                }}
                                onBlur={handleBlur('categoriaReserva')}
                            />
                            {(categoriaSelected === null && touched.categoriaReserva) &&
                                <text className='errorTextWallet'>{strings.obligatorio}</text>
                            }
                            <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center', marginTop: 30, marginBottom: 10}}>
                                <img
                                src={require('../../images/dialog_concepto_icon.png')}
                                className='iconDialogSmall'
                                alt=''
                                />
                                <text style={{color: '#6a6a6a', fontSize: 15, fontWeight: 'bold', marginLeft: 8}}>
                                    {strings.dialogReservaConcepto}
                                </text>
                            </div>
                            <div style={{width: '98%', marginLeft: 4}}>
                                <TextField
                                    id="conceptoReserva"
                                    type="text"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={handleBlur('conceptoReserva')}
                                    onChange={handleChange('conceptoReserva') }
                                    value={ values.conceptoReserva }
                                    variant="standard"
                                    className='customInput'
                                />                
                                {(errors.conceptoReserva && touched.conceptoReserva) &&
                                    <text className='errorTextWallet'>{errors.conceptoReserva}</text>
                                }
                            </div>
                        </div>
                        {
                            btnAvailable ?
                            <Button 
                                style={{borderRadius: 14, width: '100%', height: 45, backgroundColor: '#fc5b9b', marginTop: 30, marginBottom: 24, textAlign: 'center'}}
                                onClick={()=> btnAvailable && handleSubmit()}
                            >
                                <text style={{color: 'white', fontSize: 22, fontWeight: 'bold'}}>
                                    {strings.walletReservar}
                                </text>
                            </Button>
                            :
                            <div style={{textAlign: 'center'}}>
                                <CircularProgress style={{ height: 45, width: 45, marginTop: 30, marginBottom: 22, color: '#fc5b9b'}} />
                            </div>
                        }
                    </DialogContent>
                </Dialog>
            )}
            </Formik>
        </>
    );
};
