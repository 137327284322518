import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getUserRest } from '../../redux/userSlice';
import { getMisMovimientosRest } from '../../redux/misMovimientosSlice';
import { AppDispatch, RootState } from '../../store/store';
import { MyWallet } from '../../components/MyWallet/MyWallet';
import { MisMovimientos } from '../../components/MisMovimientos/MisMovimientos';
import { Propuestas } from '../../components/Propuestas/Propuestas';
import { Header } from '../../components/Header/Header';
import { llamarServidorPorMinutos } from '../../utils/utils';
import { LAST_RECOVER_USER } from '../../utils/apiConstants';

import './HomePage.css';

interface HomePageProps {
    //ejemplo?: string;
}

export const HomePage : React.FC<HomePageProps> = props => {
  /*const {
    ejemplo,
  } = props;*/

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();  

  const token = useSelector((state : RootState) => state.auth.token); 
  const user : User | null = useSelector((state : RootState) => state.user.currentUser);
  //console.log(user);

  useEffect(() => {
    if (token === null) {
      navigate('/')
    }    
  }, [token, navigate]);

  useEffect(() => {
    if (user !== null){
      dispatch(getMisMovimientosRest(user));
    }
    
    if (user === null || (user !== null && llamarServidorPorMinutos(LAST_RECOVER_USER, 10))){   //(Cada 10 minutos)
      dispatch(getUserRest());
    }
  }, [dispatch, user]);
 
  return (   
    <div className='contentHome'> 
      <Header />
      <div className='grid' style={{paddingTop: 10}}>           
        <MyWallet />
        <MisMovimientos />       
        <Propuestas /> 
      </div> 
    </div> 
  );
}  
