
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { 
  Button,
  Paper,
  MenuItem, 
  MenuList,
  ListItemIcon,
  ListItemText,
  Popper,
  Grow,
  ClickAwayListener
} from '@mui/material';
import { Home, Settings, Logout } from '@mui/icons-material';

import { strings } from '../../language/strings';
import { AppDispatch, RootState } from '../../store/store';
import { logoutAsync } from '../../redux/authSlice';
import './Header.css';


interface HeaderProps {   
}

export const Header : React.FC<HeaderProps> = props => {
    /*const {       
    } = props;*/ 

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const user : User | null = useSelector((state : RootState) => state.user.currentUser);

  ///////////////////// MENU HEADER ///////////////////////
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  //return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const menuHeader = () => {
    return (
      <div className='menuHeader' >
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <img
            src={user?.avatar_thumb_url ? user?.avatar_thumb_url : require('../../images/ic_profile.png')}
            className='avatarHeader'
            alt='' 
          /> 
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}                    
                  >                    
                    <MenuItem onClick={(event)=> {
                        navigate('/home');
                        handleClose(event);
                      }}
                    >
                      <ListItemIcon>
                        <Home fontSize="small" />
                      </ListItemIcon>
                      <ListItemText style={{textAlign: 'left'}}>Home</ListItemText>                      
                    </MenuItem>
                    <MenuItem onClick={(event)=> {
                        navigate('/profile');
                        handleClose(event);
                      }}
                    >
                      <ListItemIcon>
                        <Settings fontSize="small" />
                      </ListItemIcon>
                      <ListItemText style={{textAlign: 'left'}}>{strings.menuDatos}</ListItemText>                      
                    </MenuItem>
                    <MenuItem onClick={(event)=> {
                        dispatch(logoutAsync());
                        handleClose(event);
                      }}
                    >
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      <ListItemText style={{textAlign: 'left'}}>{strings.menuCerrar}</ListItemText>                      
                    </MenuItem>                    
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  };
  /////////////////////////////////////////////////////

    return ( 
      <Paper elevation={6} className='containerHeader' style={{backgroundColor: 'transparent'}}>
        <img
          src={require('../../images/logo_bein.png')}
          className='logoHeader'
          alt='' 
        /> 
        { menuHeader () }        
      </Paper>  
    );
}  
