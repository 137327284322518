import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { apiBeinclub } from '../api/api';
import { AppDispatch } from '../store/store';
import { URL_MIS_MOVIMIENTOS, URL_USER_ACCOUNT } from '../utils/apiConstants';
import { setLoading } from './loadingSlice';
import { logoutAsync } from './authSlice';
import { ponerSaldo, toastSuccessSimple } from '../utils/utils';

export interface MisMovimientosState {
  misMovimientos: any[],
  ingresos: any[],
  gastos: any[],
};

const initialState: MisMovimientosState = {
  misMovimientos: [],  
  ingresos: [],
  gastos: [],
};

export const movimientosSlice = createSlice({
  name: 'movimientos',
  initialState,
  reducers: {
    setMisMovimientos: (state, action) => {
      state.misMovimientos = action.payload;
    },
    setIngresos: (state, action) => {
      state.ingresos = action.payload;
    },
    setGastos: (state, action) => {
      state.gastos = action.payload;
    },
    resetMovimientos: (state) => {
      state.misMovimientos = [];
      state.ingresos = [];
      state.gastos = [];
    },
  },
});

export const getMisMovimientosRest = (user : User, withLoader : boolean = false) => async (dispatch : AppDispatch) => {
  withLoader && dispatch(setLoading(true));
    apiBeinclub.get(URL_MIS_MOVIMIENTOS + user.id)
      .then( res => {
        //console.log(res);
        if (res.data.data !== undefined && res.data.data !== null && res.data.data.length > 0) {
          const saldo = ponerSaldo(res.data.data, user.balance);
          dispatch(setMisMovimientos(saldo));
          const gastosArray = res.data.data.filter((obj : Transaction) => {
            return obj.type === 'withdraw';
          });
          dispatch(setGastos(gastosArray));
          const ingresosArray = res.data.data.filter((obj : Transaction) => {
            return obj.type === 'deposit';
          });          
          dispatch(setIngresos(ingresosArray));
        }
      }).catch((e) =>{
          console.log(e);
          if (e.response.status !== undefined && e.response.status === 401){
            //Usuario Caducado -> Logout
            //const user = JSON.parse(getLocalPreference(USER_DATA));
            //messaging().unsubscribeFromTopic(APPLICATION_ID + '-' + user.uuid).then(() => console.log('Unsubscribed to USER topic!'));
            dispatch(logoutAsync());
          }
      })
    .finally(()=> withLoader && dispatch(setLoading(false)));
};

export const resetMisMovimientos = () => async (dispatch : AppDispatch) => { 
  //ELIMINAR MOVIMIENTOS
  dispatch(resetMovimientos());  
};

export const { setMisMovimientos, setGastos, setIngresos, resetMovimientos } = movimientosSlice.actions;

export default movimientosSlice.reducer;
