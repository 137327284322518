import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
} from '@mui/material';

import { strings } from '../../language/strings';
import { SubDialogRecargarDinero } from './SubDialogRecargarDinero';
import './DialogHazteSocio.css';

interface HazteSocioProps {
    dialogVisible: boolean;
    setDialogVisible: (arg0: boolean) => void;
}

export const DialogHazteSocio : React.FC<HazteSocioProps> = props => {
    const {
        dialogVisible,
        setDialogVisible,
    } = props;

    const [ dialogRecargarDineroVisible, setDialogRecargarDineroVisible ] = useState(false);
    
    const resetBack = () => {
        setDialogVisible(false);
    };

    return (
        <>
            <SubDialogRecargarDinero
                setDialogRecargarDineroVisible={setDialogRecargarDineroVisible}
                dialogRecargarDineroVisible={dialogRecargarDineroVisible}
                concepto={strings.hazteSocio.btnText}  
            />
            <Dialog 
                open={dialogVisible}
                onClose={resetBack}>
                <DialogContent className='fondoDialog' style={{textAlign: 'center'}}>                   
                    <b style={{fontSize: 21}}>{strings.hazteSocio.title}</b>
                    <div style={{textAlign: 'left'}}>
                        <p style={{whiteSpace: 'pre-line', marginTop: 48, marginBottom: 55, marginRight: 8}}>
                            <p>
                                {strings.hazteSocio.text}
                            </p>
                            <p style={{fontSize: 14, fontWeight: 'bold', marginLeft: 18, marginRight: 18}}>
                                {strings.hazteSocio.text2}
                            </p>
                        </p>
                    </div>                  
                    <Button 
                        variant="contained"
                        color="primary"
                        size='small'
                        className='btnLogin'
                        onClick={()=> {
                            setDialogVisible(false);
                            setDialogRecargarDineroVisible(true);
                        }}
                        style={{fontSize: 15}}
                    >
                        {strings.hazteSocio.btnText}
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};
