import { WEB_TOKEN } from './apiConstants';
import { toast } from 'react-toastify';
import { strings } from '../language/strings';
import moment from 'moment';

export const recoverWebCredentialsAndroid = async (login : string, password : string) => {

    // De momento desactivamos el login a Wordpress al existir muchos Wordpress distintos
    localStorage.setItem(WEB_TOKEN, '');

    /*
    var requestOptions = {
        method: 'POST',
    };
    fetch('https://beyondinner.nusgordia.com/?rest_route=/simple-jwt-login/v1/auth&email=' + login + '&password=' + password, requestOptions)
        .then(
        function(response) {
            response.json().then(json => {
                setLocalPreference(WEB_TOKEN, json.data.jwt);
            });
        }
        )
        .catch(error => console.log('error', error));
    */
};

export const llamarServidorPorMinutos = (nombreLlamadaStorage : string, minutosSiguienteLlamada : number) =>{
    const ultimaLlamada = localStorage.getItem(nombreLlamadaStorage);
    if (ultimaLlamada === undefined || ultimaLlamada === null){
        return true;
    }
    const llamadaInMilliseconds = new Date(parseInt(ultimaLlamada, 10)).getTime();
    const nowMilliseconds = new Date().getTime();
    if ((Math.floor(((nowMilliseconds - llamadaInMilliseconds) / 1000) / 60)) >= minutosSiguienteLlamada) {
        return true;
    }
    return false;
};

export const toastSuccessSimple = (message : string) =>{
    toast.success(message, {
        toastId: message,
        position: "top-right",
        style: {color: '#005a00'},
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        hideProgressBar: true,
        theme: "light",
    });
};

export const toastErrorSimple = (message : string) =>{
    toast.error(message, {
        toastId: message,
        position: "top-right",
        style: {color: 'white', backgroundColor: '#420D09'},
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: false,
        hideProgressBar: true,
        theme: "dark",
    });
};

export const reorderArrayforId = (array : any[]) => {
    array.sort(function(x,y) {
        if (x.id > y.id) {
            return 1;
        }
        if (x.id < y.id) {
            return -1;
        }
        return 0;
    });
    return array;
};

export const ponerSaldo = (movimientosArray : Transaction[], userBalance: string) => {
    const alReves = movimientosArray.reverse();
    let balance = parseInt(userBalance);
    let arrayNew : Transaction[] = [];
    alReves.forEach( (element, index) => {
      if (index === 0) {
        arrayNew.push({...element, saldo: balance});
      } else {
        balance = balance - parseInt(alReves[index - 1].amount); // si es withdraw suma sino resta
        arrayNew.push({...element, saldo: balance});
      }
    });
    return arrayNew;
};

export const formatDate = (fechaString : string) => {
    const lang = strings.getInterfaceLanguage();
    if (lang.startsWith('es-') || lang.startsWith('ca-')) {
        return moment(new Date(fechaString)).format('DD-MM-YYYY');
    }

    return moment(new Date(fechaString)).format('YYYY-MM-DD');
};

export const getFormatCalendario = () => {
    const lang = strings.getInterfaceLanguage();
    if (lang.startsWith('es-') || lang.startsWith('ca-')) {
        return 'DD-MM-YYYY';
    }

    return 'YYYY-MM-DD';
};

export const getZonasFromPhoneLanguage = (item : any) => {
    //FIX para Eventos (en vez de Ciudad)
    if (item.name === 'Events') {
        return 'Events';
    }
    
    const lang = strings.getInterfaceLanguage();

    if (lang.startsWith('es-')) { return item.es.name; }
    if (lang.startsWith('es-') || lang.startsWith('ca-')) { return item.es.name; }
    //if (lang.startsWith('ca-')) { return item.ca.name; }
    if (lang.startsWith('fr-')) { return item.fr.name; }
    if (lang.startsWith('it-')) { return item.it.name; }
    if (lang.startsWith('de-')) { return item.de.name; }
    if (lang.startsWith('ro-')) { return item.ro.name; }
    if (lang.startsWith('tr-')) { return item.tr.name; }
    return item.en.name; //default English
};

export const getTextNameFromPhoneLanguage = (item : any) => {
    const lang = strings.getInterfaceLanguage();
    
    if (lang.startsWith('es-')) { return item.nameEs; }
    if (lang.startsWith('ca-')) { return item.nameCa; }
    if (lang.startsWith('fr-')) { return item.nameFr; }
    if (lang.startsWith('it-')) { return item.nameIt; }
    if (lang.startsWith('de-')) { return item.nameDe; }
    if (lang.startsWith('ro-')) { return item.nameRo; }
    if (lang.startsWith('tr-')) { return item.nameTr; }
    return item.nameEn; //default English
};

export const getTextSubNameFromPhoneLanguage = (item : any) => {
    const lang = strings.getInterfaceLanguage();

    if (lang.startsWith('es-')) { return item.subnameEs; }
    if (lang.startsWith('ca-')) { return item.subnameCa; }
    if (lang.startsWith('fr-')) { return item.subnameFr; }
    if (lang.startsWith('it-')) { return item.subnameIt; }
    if (lang.startsWith('de-')) { return item.subnameDe; }
    if (lang.startsWith('ro-')) { return item.subnameRo; }
    if (lang.startsWith('tr-')) { return item.subnameTr; }
    return item.nameEn; //default English
};
