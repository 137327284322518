import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import authSlice from '../redux/authSlice';
import loadingSlice from '../redux/loadingSlice';
import movimientosSlice from '../redux/misMovimientosSlice';
import resourcesBeinSlice from '../redux/resourcesBeinSlice';
import userSlice from '../redux/userSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    user: userSlice,
    loading: loadingSlice,
    resources: resourcesBeinSlice,
    movimientos: movimientosSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),  
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
