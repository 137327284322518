import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '../../store/store';

interface LayoutPageProps {
    //ejemplo?: string;
}

export const LayoutPage : React.FC<LayoutPageProps> = props => {
  /*const {
    ejemplo,
  } = props;*/ 

  const token = useSelector((state : RootState) => state.auth.token); 
  //console.log(token);

  return (
    <div>
      {
        token === null ?
        <Navigate to="/login"/>
        :
        <Navigate to="/home"/>
      }      
    </div>
  );
}  
