import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getResourcesBein } from './redux/resourcesBeinSlice';

import { router } from './route/routes';
import { AppDispatch } from './store/store';

function App() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getResourcesBein());
  }, [dispatch]);

  return (
    <>
      <RouterProvider router={router} /> 
    </>
  );
}

export default App;
