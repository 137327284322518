import { CircularProgress } from '@mui/material';
import React from 'react';
import LocalizedStrings from 'react-localization';

import './LoaderSpinnerOverlay.css';

const strings = new LocalizedStrings({
    en: {
        loading:                                'Loading...',
    },
    es: { 
        loading:                                'Cargando...',
    },
    ca: {
        loading:                                'Carregant...',      
    },
});

interface LoaderSpinnerOverlayProps {
    visible: boolean;
    paddingBottom?: number;
}

export const LoaderSpinnerOverlay : React.FC<LoaderSpinnerOverlayProps> = props => {
    const {
        visible = false,
        paddingBottom = 0,
    } = props;   
    return ( 
        <div className='contentLoader' style={{visibility: visible ? 'visible' : 'hidden', paddingBottom: paddingBottom}}>
            <div>
                <CircularProgress style={{width: 60, height: 60, marginBottom: 10, color: '#ff0066'}}/>
                <h5 className='title'>{strings.loading}</h5>
            </div>             
        </div>
    );
}  
