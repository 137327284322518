
import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
} from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';

import { strings } from '../../language/strings';
import { toastErrorSimple, toastSuccessSimple } from '../../utils/utils';

import './RecoverWidget.css';
import { apiBeinclub } from '../../api/api';
import { URL_CHANGE_PASSWORD_PIN, URL_RESET_PASSWORD } from '../../utils/apiConstants';
import { LoaderSpinnerOverlay } from '../Loader/LoaderSpinnerOverlay';

interface RecoverWidgetProps { 
  setWidget:(widget : number)=>void;  
}

export const RecoverWidget : React.FC<RecoverWidgetProps> = props => {
  const { 
    setWidget,      
  } = props;

  const [ loading, setLoading ] = useState(false);
  const [ modoPin, setModoPin ] = useState(false);
  const [ email, setEmail ] = useState('');

  const formEmpty = {
    pin: '',
    newPass: '',
    repeatPass: '',
  };

  const [valoresFormulario, setValoresFormulario] = useState(formEmpty);

  function validateEmail(emailPrueba : string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(emailPrueba);
  }

  const requestPin = (e : React.SyntheticEvent) => {
    e.preventDefault();
    if (email.length === 0) {
      toastErrorSimple('Email ' + strings.obligatorio);
    } else if (!validateEmail(email)) {
      toastErrorSimple(strings.errorMail);
    }
    else {
      //OK solicitar cambio
      setLoading(true);
      apiBeinclub.post(URL_RESET_PASSWORD, {'email': email})
        .then( res => {
            console.log(res)
            if (res.data.status === true) {
                toastSuccessSimple(strings.pinSended + email + strings.verificaPass);                
                setModoPin(true);
            } else {
                //Algo ha ido mal!
                toastErrorSimple(strings.mal);
            }
        }).catch((error) =>{
            console.log(error);
            if (error.request.status === 400){
                toastErrorSimple(strings.emailUnreg);
            } else {
              toastErrorSimple(strings.mal);
            }
        })
        .finally(()=>  setLoading(false));
    }
  };

  const changePassword = (e : React.SyntheticEvent) => {
    e.preventDefault();
    if (valoresFormulario.pin.length < 5) {
      toastErrorSimple(strings.caractErrorPin);
  } else if (valoresFormulario.newPass.length < 6 ) {
    toastErrorSimple(strings.caractError);
  } else if (valoresFormulario.repeatPass.length === 0) {
    toastErrorSimple(strings.repetirError);
  } else if (valoresFormulario.newPass !== valoresFormulario.repeatPass) {
    toastErrorSimple(strings.passNoMatchError);
  } else {
      //OK solicitar cambio
      setLoading(true);
      const params = {
          'email': email,
          'pin': valoresFormulario.pin,
          'new-password': valoresFormulario.newPass,
      }
      apiBeinclub.post(URL_CHANGE_PASSWORD_PIN, params)
      .then( res => {
          console.log(res)
          setLoading(false);
          if (res.data.status === true) {
              toastSuccessSimple(strings.updatePass);
              setTimeout(function(){
                  setWidget(0);
              }, 2500);
          } else {
              //Algo ha ido mal!
              toastErrorSimple(strings.mal);
          }
      }).catch((error) =>{
          console.log(error);
          setLoading(false);
          if (error.request.status === 400){
            toastErrorSimple(strings.emailUnregPin);
          } else {
            toastErrorSimple(strings.mal);
          }
      });
    }
  };

  return ( 
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: 300 },
      }}
      noValidate
      autoComplete="off"
      style={{textAlign: 'center', margin: 'auto', padding: 32, paddingBottom: 32, backgroundColor: '#FFFFFF40', borderRadius: 16}}
    > 
      <LoaderSpinnerOverlay visible={loading}/> 
      <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', position: 'relative'}}>               
        <ChevronLeft style={{fontSize: 50, position: 'absolute', left: -12, top: 4}} onClick={()=> setWidget(0)}/> 
        <img
          src={require('../../images/logo_bein.png')}
          style={{width: 210, height:70, marginBottom: 6}}
          alt='' 
        />                                   
      </div>
      <div style={{marginBottom: 32, marginTop: 8}}>
        <text style={{ fontWeight: 'bold', fontSize: 17 }}>
          {strings.recoverTitle}
        </text>
      </div>      
      {
        modoPin ?
        <>
          <div>
            <TextField
              id="pincode"
              label={strings.recoverPinPlaceholder}
              value={valoresFormulario.pin}
              size='small'
              onChange={event=> setValoresFormulario({...valoresFormulario, pin: event.target.value.trim()})}           
            />
          </div>
          <div>
            <TextField
              id="newPass"
              label={strings.newPass}
              value={valoresFormulario.newPass}
              size='small'
              onChange={event=> setValoresFormulario({...valoresFormulario, newPass: event.target.value.trim()})}           
            />
          </div>
          <div>
            <TextField
              id="repeatPass"
              label={strings.repetir + strings.asterisco}
              value={valoresFormulario.repeatPass}
              size='small'
              onChange={event=> setValoresFormulario({...valoresFormulario, repeatPass: event.target.value.trim()})}           
            />
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              size='small'
              className='btnLogin' 
              disabled={loading}
              onClick={changePassword} 
              style={{marginTop: 24, fontWeight: 'bold'}}      
            >
              {strings.enviarBig}
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              size='small'
              className='btnLogin' 
              disabled={loading}
              onClick={() => setModoPin(false)} 
              style={{marginTop: 16, fontWeight: 'bold', backgroundColor: '#cc9900'}}      
            >
              {strings.recoverAgainBtn}
            </Button>
          </div>
        </>
        :
        <>
          <div>
            <TextField
              required
              id="email"
              label={strings.email}
              value={email}
              size='small'
              onChange={event=> setEmail(event.target.value.trim())}           
            />
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              size='small'
              className='btnLogin' 
              disabled={loading}
              onClick={requestPin} 
              style={{marginTop: 24, fontWeight: 'bold'}}      
            >
              {strings.recoverBtn}
            </Button>
          </div> 
        </>  
      }
    </Box>
  );
}  
