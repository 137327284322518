import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { apiBeinclub, apiBeinclubUnsecured } from '../api/api';
import { AppDispatch } from '../store/store';
import { CURRENT_USER, LAST_RENEW_TOKEN, URL_LOGIN, URL_SIGNUP, USER_TOKEN } from '../utils/apiConstants';
import { setLoading } from './loadingSlice';
import { toastErrorSimple } from '../utils/utils';
import { strings } from '../language/strings';
import { resetCurrentUser } from './userSlice';
import { resetMisMovimientos } from './misMovimientosSlice';
//import { recoverWebCredentialsAndroid } from '../utils/utils';

export interface AuthState {
   token: string | null,
};

const initialState: AuthState = {
   token: localStorage.getItem(USER_TOKEN),
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setLogout: (state) => {
      state.token = null;
    },
  },
});

export const loginAsync = (email : string, password : string) => async (dispatch : AppDispatch) => {  
  dispatch(setLoading(true));
  apiBeinclubUnsecured.post(URL_LOGIN, JSON.stringify({'email': email, 'password': password }))
    .then( res => {
        //console.log(res);        
        /*Platform.OS === 'android' ?
        recoverWebCredentialsAndroid(email, res.data.wp);
        //:
        //recoverWebCredentials(email, password);*/
        localStorage.setItem(USER_TOKEN, res.data.access_token);
        localStorage.setItem(LAST_RENEW_TOKEN, new Date().getTime().toString());
        dispatch(setToken(res.data.access_token));
    }).catch((e) =>{
        console.log(e);
        if (e.response.status !== undefined && e.response.status === 401){
          toastErrorSimple(strings.toastErrorLogin);                  
        } /*else if (e.response.status !== undefined && e.response.status === 403){
          toastErrorSimple(strings.toastErrorPass);    
        }*/
        else if (e.response.status !== undefined && e.response.status === 409){
          toastErrorSimple(strings.toastErrorLoginSocial);   
        }
        else {
          toastErrorSimple(strings.mal);   
        }
    })
    .finally(()=> dispatch(setLoading(false)));
};

export const logoutAsync = () => async (dispatch : AppDispatch) => { 
  //ELIMINAR TODO
  localStorage.clear(); 
  dispatch(resetCurrentUser());
  dispatch(resetMisMovimientos());
  dispatch(setLogout()); 
};

export const { setToken, setLogout } = authSlice.actions;

export default authSlice.reducer;
